const ROOTS_ADMIN = "/";
const ROOTS_CANDIDATE = "/";
const ROOTS_RECRUITER = "";
const ROOTS_AUTH = "/";
const ROOTS_COMMON = "/";
const ROOTS_CANDIDATE_ACCOUNT = "/";
const path = (root, sublink) => `${root}${sublink}`;

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  dashboard: path(ROOTS_ADMIN, "dashboard"),
  clientDetail: path(ROOTS_ADMIN, "clientDetail"),
};
export const PATH_CANDIDATE_ACCOUNT = {
  root: ROOTS_CANDIDATE_ACCOUNT,
  tests: path(ROOTS_CANDIDATE_ACCOUNT, "candidateTests"),
  profile: path(ROOTS_CANDIDATE_ACCOUNT, "profile"),
};
export const PATH_CANDIDATE = {
  root: ROOTS_CANDIDATE,
  videoInterview: path(ROOTS_CANDIDATE, "videoInterview"),
  mediaAccess: path(ROOTS_CANDIDATE, "mediaAccess"),
  getStarted: path(ROOTS_CANDIDATE, "getStarted"),
  submitted: path(ROOTS_CANDIDATE, "submitted"),
  signIn: path(ROOTS_CANDIDATE, "signIn"),
  trialInterview: path(ROOTS_CANDIDATE, "trialInterview"),
  screening: path(ROOTS_CANDIDATE, "screening"),
  details: path(ROOTS_CANDIDATE, "details"),
};
export const PATH_RECRUITER = {
  root: ROOTS_RECRUITER,
  createTest: path(ROOTS_RECRUITER, "/createTest"),
  editTest: path(ROOTS_RECRUITER, "/editTest"),
  myTests: path(ROOTS_RECRUITER, "/tests"),
  myApplicants: path(ROOTS_RECRUITER, "/myApplicants"),
  userAccount: path(ROOTS_RECRUITER, "/userAccount"),
  manageMembers: path(ROOTS_RECRUITER, "/manageMembers"),
  reportCard: path(ROOTS_RECRUITER, "/reportCard"),
  transcript: path(ROOTS_RECRUITER, "/reportTranscript"),
  link: path(ROOTS_RECRUITER, "link"),
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, ""),
  signup: path(ROOTS_AUTH, "/signup"),
  forgotPassword: path(ROOTS_AUTH, "forgotPassword"),
  resetPassword: path(ROOTS_AUTH, "/resetPassword"),
};
export const PATH_COMMON = {
  root: ROOTS_COMMON,
  sessionTimeout: path(ROOTS_COMMON, "/sessionTimeout"),
  pricing: path(ROOTS_COMMON, "/pricing"),
  public: path(ROOTS_COMMON, "/public"),
  pageNotFound: path(ROOTS_COMMON, "404"),
  transcript: path(ROOTS_COMMON, "publicReportTranscript"),
  candidateOnboarding: path(ROOTS_COMMON, "onboarding"),
  testCandidates: path(ROOTS_COMMON, "publicTestCandidates"),
  candidateProfile: path(ROOTS_COMMON, "publicCandidateProfile"),
};
