export default {
  login: {
    heading: "Welcome back",
    subHeading: "Sign in to TestDojo",
    signUpLinkTitle: "Don't have an account?",
    signUpLinkText: "Sign Up",
    emailAddressInputLabel: "Email Address",
    passwordInputLabel: "Password",
    rememberMeLinkText: "Remember Me",
    forgetPasswordLinkText: "Forgot Password?",
    buttonText: "Login",
    emptyEmailValidationText: "Email is required",
    validEmailValidationText: "Email must be a valid email address",
    emptyPasswordValidationText: "Password is required",
    adminEmails: ["admin@thebrewapps.com"],
  },
  signUp: {
    heading: "Create an account",
    subHeading: "Let's get started with your 30 day free trial.",
    signUpLinkTitle: "Already have an account?",
    signUpLinkText: "Sign In",
    firstNameInputLabel: "First Name",
    lastNameInputLabel: "Last Name",
    countryCodeInputLabel: "Country Code",
    phoneNumberInputLabel: "Phone Number",
    companyNameInputLabel: "Company Name",
    jobRoleInputLabel: "Job Role",
    emailAddressInputLabel: "Email",
    passwordInputLabel: "Password",
    buttonText: "Sign Up",
    verficationLinkToasterMessageText:
      "A verification link has been sent to your email account",
    onlyCharValidationFirstNameText: "Only characters are allowed",
    maxCharValidationFirstNameText: "A maximum of 25 characters is allowed",
    onlyCharValidationLastNameText: "Only characters are allowed",
    maxCharValidationLastNameText: "A maximum of 25 characters is allowed",
    validCountryPhoneNumberValidationText:
      "Please enter a valid phone number for the selected country",
    onlyCharValidationCompanyNameText: "Only characters are allowed",
    validEmailValidationText: "Email must be a valid email",
    validPasswordValidationText:
      "The password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special case character",
    emptyValidationText: {
      firstName: "First name required",
      lastName: "Last name required",
      companyName: "Company name required",
      phoneNo: "Phone Number is required",
      email: "Email is required",
      password: "Password is required",
      jobRole: "Job Role required",
      countryCode: "Country Code is required",
    },
  },
  forgetPassword: {
    heading: "Forgot your password?",
    subHeading:
      " Please enter your email, and if you have an account with us, we will send you an email with the link to reset your password",
    emailAddressLabel: "Email Address",
    sendButton: "Send",
    signinHyperLink: "Return to Sign In",
    emptyEmailValidationText: "Email is required",
    validEmailValidationText: "Email must be a valid email address",
    emailSucessfullySentToasterMessage: "Link Successfully sent to",
  },
  resetPassword: {
    heading: "Reset Password",
    passwordInputLabel: "Password",
    confirmPasswordInputLabel: "Confirm Password",
    updatePasswordButton: "Update Password",
    emptyPasswordValidationText: "Password is required",
    validPasswordValidationText:
      "The password must contain 8 characters, one uppercase, one lowercase, one number, and one special case character",
    emptyConfirmPasswordValidationText: "Confirm password is required",
    passwordNotMatchingValidation: "Passwords must match",
  },
  myTests: {
    createAssessmentButton: "Create New",
    tableHeader: {
      testNameTitle: "Job Role",
      dateCreatedTitle: "Date Created",
      applicantsTitle: "Applicants",
      actionsTitle: "Actions",
      inviteTitle: "Invite",
    },
    linkToolTip: "Link",
    active: "Active",
    archive: "Archive",
    popupMessage: {
      delete:
        "Deleting this test will permanently erase all related data, including candidate results. Are you sure? ",
      duplicate: "Are you sure? you want to duplicate this test?",
    },
    type: {
      delete: "delete",
      duplicate: "duplicate",
    },
    dropdownTitles: {
      edit: "Edit",
      unArchive: "Unarchive",
      archive: "Archive",
      delete: "Delete",
      duplicate: "Duplicate Test",
    },
    creatNewBtn: "Create New",
    aiTestOption: "AI test",
    mcqTestOption: "Custom test",
  },
  navigationBar: {
    titles: {
      myTests: "My Tests",
      myApplicants: "My Applicants",
    },
    aiTestTitle: "AI Test",
    profileDropdownOptions: {
      homeTitle: "Home",
      manageMembers: "Manage Members",
      logOutOption: "Log out",
    },
    adminDashboard: "Dashboard",
    candidate: {
      test: "Tests",
      profile: "Profile",
    },
  },
  myApplicants: {
    tableHeadersTitles: {
      candidate: "Candidate",
      testName: "Test Name",
      hiringStage: "Hiring Stage",
      jobRole: "Job Role",
      score: "Score",
      appearedOn: "Appeared On",
    },
    searchCandidatesPlaceholder: "Search Candidates",
    jobRoleDropdownLabel: "Job Role",
    testNameDropdownLabel: "Test Name",
    applicantNotFound: "No Applicants found with the name",
  },
  summarization: {
    tableHeadersTitles: {
      candidate: "Candidates",
      hiringStage: "Hiring Stage",
      score: "Score",
      status: "Status",
      appearedOn: "Appeared On",
      essayRating: "Essay Rating",
    },
    filtersActiveAll: {
      active: "Active",
      all: "All",
    },
    sortButton: "Sort",
    filterButton: "Filter",
    shareBtnText: "Share Test Report",
    shareBtnToolTipText: "Share Test Report",
    shareTitleText: "Share Test Report",
    filters: {
      candidateSearchLabel: "Candidate",
      searchCandidatesPlaceholder: "Search Candidate",
      hiringStageDropdownLabel: "Hiring Stage",
      hiringStageDropdownPlaceholder: "Select Hiring Stage",
      scoreRangeLabel: "Score",
      dateLabel: "Date",
      dateOptions: {
        inBetweenTitle: "In Between",
        beforeTitle: "Before",
        afterTitle: "After",
      },
      resetButton: "Reset",
    },
    sorts: {
      orderByOptions: {
        label: "Order By",
        ascendingTitle: "Ascending",
        descendingTitle: "Descending",
      },
      resetButton: "Reset",
    },
    linkCopiedMessage: "Link copied to clipboard",
    enableLinkLabel: "Enabled",
    disableLinkLabel: "Disabled",
    disabledLinkToolTip: "Toggle On To Enable Link",
    enabledLinkToolTip: "Toggle Off To Disable Link",
    publicLinkLabel: "Public Link",
    linkDisabledErrorMessage: "Link is expired or disabled",
  },
  table: {
    unGradedBtn: "Ungraded",
    moreDetailsBtn: "More Details",
    moreDetailsTitle: "Question Analysis",
    totalQuestionsMoreDetails: "Total Questions",
    attemptedMoreDetails: "Attempted",
    unAttemptedMoreDetails: "Unattempted",
    correctMoreDetails: "Correct",
    wrongMoreDetails: "Wrong",
    testIsEmpty: "The Test created is empty",
    abandonedTagText: "Abandoned",
  },
  reportCard: {
    reportCardHeader: "Report Card",
    candidatePanelHeader: "Candidates",
    candidateRankCardTitle: "Candidate Rank",
    averageScoreCardTitle: "Average Score",
    closeNotesBtn: "Save",
    linkCopiedMessage: "Link copied to clipboard",
    enableLinkLabel: "Enabled",
    disableLinkLabel: "Disabled",
    disabledLinkToolTip: "Toggle On To Enable Link",
    enabledLinkToolTip: "Toggle Off To Disable Link",
    publicLinkLabel: "Public Link",
    notesBtn: "Notes",
    filters: {
      candidateSearchLabel: "Candidate",
      searchCandidatesPlaceholder: "Search Candidate",
    },
    skippedTagText: "Skipped",
    skippedQuesPlaceholder: "The candidate chose to skip this question.",
    summaryHeader: "Candidate Response Summary",
    ratingheader: "AI Rating",
    ratingExpHeader: "AI Rating Explanation",
    shareIconText: "Share",
    noteIconText: "Notes",
    resumeIconText: "Resume",
    pencilIconToolTip: "Tap on stars to adjust rating",
    docPreviewTitle: "Resume preview",
    linkedInText: "Linkedin",
    shareTitleText: "Share Profile",
    publicLinkTooltip: "Public Link",
    privateLinkTooltip: "Private Link",
    testDateTooltip: "Test Date",
    availableToStartDateTooltip: "Available to Start",
    expandTooltip: "Expand Questions Panel",
    collapseToolTip: "Collapse Questions Panel",
    fullscreenEditorTooltip: "View Editor in Fullscreen",
    followUpTagText: "Follow Up",
    downloadAttachmentBtnText: "Download Attachment",
    downloadResumeBtn: "Download",
    questionTitle: "Question",
    unattemptedTagTitle: "Unattempted",
    followUpText: "A",
    interviewQuesHeader: "AI-Interviewer",
    quesIndicatorText: "Questions",
    achievmentsTitles: {
      score: "Score",
      rank: "Rank",
    },
    shareBtnToolTipText: "Share Report",
    notesBtnText: "Notes",
    shareBtnText: "Share Report",
    notesToolTipText: "View Notes",
    suspiciousActivityLabel: "Suspicious Activity Found",
    suspiciousActivityAnswers: {
      yes: "Yes",
      no: "No",
    },
    suspiciousActivityToolTip: "View Suspicious Images",
    viewImagesBtn: "View images",
    linkedinTooltip: "View LinkedIn Profile",
    resumeBtnToolTipText: "View Resume",
    linkedinBtnText: "View LinkedIn",
    resumeBtnText: " View Resume",
    infoTitles: {
      contact: "Contact",
      email: "Email",
      role: "Role",
      testDate: "Test Date",
      availableToStart: "Available to Start",
      location: "Location",
    },
    reportCardTabNames: {
      detailSummary: "Summary",
      screeningQuestions: "Screening Questions",
    },
    viewTranscriptBtnText: "View Transcript",
    ratingHeader: "Score : ",
    feedbackTitle: "Feedback : ",
    transcriptHeader: "Transcript",
    transcriptTitle: "Transcript",
    summaryTitle: "Summary",
    ratingTitle: "Score : ",
    followUpTitle: "Follow-up",
    skippedTitle: "Skipped",
    screeningQuesEmptyPlaceholder: "No screening questions were set",
    detailSummaryQuesEmptyPlaceholder: "Detail summary not found ",
    skippedQuesTitle: "The candidate chose to skip this question",
  },
  modal: {
    saveBtn: "Save",
    cancelBtn: "Cancel",
  },
  linkGeneration: {
    privateLinkHeader: "Private Link Invitation",
    publicLinkHeader: "Public Link",
    emailValidationMessage: "Email must be in valid format",
    heading:
      "You can either share the following link with the candidates or invite them by adding their emails below",
    publicLinkLabel: "Link",
    linkCopiedMessage: "Copied to clipboard!",
    orDividerTitle: "or",
    nameLabel: "Name",
    emailLabel: "Email",
    addAnotherOption: "Add another candidate",
    privateLinkInvitationSuccessMessage: "Invite sent Successfully",
    inviteBtn: "Invite",
    enableLinkLabel: "Link Enabled",
    disableLinkLabel: "Link Disabled",
    disabledLinkToolTip: "Toggle On To Enable Link",
    enabledLinkToolTip: "Toggle Off To Disable Link",
    tableHeader: {
      name: "Name",
      email: "Email",
      invitedOn: "Invited On",
      status: "Test Taken",
      actionsTitle: "Actions",
    },
    reInviteTableBtn: "Reinvite",
    viewReportBtn: "View Report",
    duplicateEmailValidationMessage:
      "Email Address Already Entered: Please provide a unique email for each candidate to continue",
    emailAlreadyExistsMessage:
      "Invitation for this email has already been sent",
    copyPublicLinkBtnTooltip: "Click to copy the public link",
    invitedMembersTableHeader: "Invited Members",
    backBtn: "Back",
    reinviteMemberSuccessMessage: {
      preffix: "Email: ",
      suffix: " reinvited Successfully",
    },
    reinviteMemberErrorMessage: {
      preffix: " Failed To Send Invite! Please Try Again! ",
      suffix: "Email: ",
    },
  },
  dialogPopUp: {
    yesBtn: "Yes",
    noBtn: "No",
  },
  candidateSignin: {
    nameFieldMaxCharValidation: "A maximum of 25 characters is allowed",
    nameFieldEmptyValidation: "Name is required",
    nameFieldOnlyCharAllowedValidation: "Only characters are allowed",
    emailFieldEmptyEmailValidation: "Email is required",
    emailFieldValidEmailValidation: "Email must be a valid email address",
    browserNotCompatibleMessage:
      "This test cannot be taken in Safari; please switch to Chrome.",
    testNotAllowedInMobileMessage: "Test cannot be given on a mobile device",
    fourDigitNumberValidationMsg: "Please enter a 4-digit verification code",
    signInfoPrefix: "Welcome to the",
    signInfoPostfix: "test",
    privateLinkHeading:
      "Please enter the unique verification code that was sent in the invitation email",
    publicLinkHeading: "Please provide the following details to proceed",
    unAuthorizedErrorMessage:
      "Sorry, this link is no longer valid. It may have expired or been deactivated",
    nameFieldLabel: "Name",
    emailFieldLabel: "Email Address",
    nextBtn: "Next",
  },
  getStarted: {
    headerPrefix: "Welcome to the",
    headerPostfix: "test By",
    testDurationTitle: "Test duration",
    noOfQuestionSetsTitle: "No of question sets",
    instructions: {
      namePrefix: "👋 Hi",
      header:
        "📌 Important Instructions: Please read carefully before proceeding.",
      points: [
        {
          icon: "1️⃣",
          head: " One Shot: ",
          headValue:
            " You can take this test only once, and it must be completed in one sitting.",
        },
        {
          icon: "2️⃣",
          head: " Video-based Interview:",
          subPoints: [
            {
              icon: "📹",
              text: " Your camera must be turned on.",
            },
            {
              icon: "🎙️",
              text: " Your microphone must be functional.",
            },
          ],
        },
        {
          icon: "3️⃣",
          head: " ⏰Timer:",
          headValue:
            "Keep an eye on the clock. If you run out of time, your response up to that point will be submitted automatically.",
        },
        {
          head: "4️⃣ Tech Requirements:",
          subPoints: [
            {
              icon: "🌐",
              text: " Good internet connection",
            },
            {
              icon: "🎙️",
              text: " Microphone enabled",
            },
            {
              icon: "🖥️",
              text: " Must be taken on a computer using Chrome Browser",
            },
          ],
        },
        {
          icon: "5️⃣",
          head: " Full-screen Mode: ",
          headValue:
            "🚫 Do not exit full-screen or refresh the page as it will invalidate your session.",
        },
        {
          head: "6️⃣ Question Format:",
          subPoints: [
            {
              icon: "🕒",
              text: " 10-second prep time for each question.",
            },
            {
              icon: "🔜",
              text: " Once you submit, proceed to the next question.",
            },
            {
              icon: "❓",
              text: " Follow-up questions may be asked by our AI for more details.",
            },
          ],
        },
      ],
      noteText: {
        icon: "🚫",
        head: "NOTE: ",
        headValue:
          "Avoid hitting the refresh or back button in your browser at any time during the test.",
      },
    },

    allTheBest: "🍀 All the Best! 👍",
    continueBtn: "Continue",
  },
  mediaAccess: {
    heading: "Camera Access",
    points: [
      {
        icon: "📹",
        text: "Camera Access Required",
        subText:
          "This is a video screening interview, so camera access will be necessary throughout the test.",
      },
      {
        icon: "🛡️",
        text: "This measure is in place to ensure a fair and standardized experience for all candidates.",
      },
      {
        icon: "👍",
        text: "Your camera will automatically be turned off as soon as you complete the test.",
      },
      {
        icon: "🌐",
        text: "For the best experience, please use the latest and most stable version of the Chrome browser.",
      },
      {
        icon: "💻",
        text: "For macOS Users: Screen Recording Permission",
        subText:
          "If you're using macOS, enable screen recording permission. Go to System Settings → Privacy & Security → Screen Recording → Toggle on for Chrome. This step is essential for the test.",
      },
    ],
    mediaPermissionDeniedMessage:
      "We are unable to access your camera or microphone. Please click on the camera icon and micrphone icon in your browser's address bar and refresh the page to enable the camera and microphone.",
    nextBtn: "Next",
    screenShareErrorMessage:
      "Permission required! Please retry and select entire screen!",
    permissionDeniedBySystemErrMessage:
      "Access Denied: Please grant the required system permissions to proceed.",
    accessDeniedMessage: "Error accessing user media",
  },
  videoInterview: {
    getQuesLoaderText:
      "Please wait your questions are being generated by AI .....",
    questionTitle: "Question ",
    duration: "Duration:",
    heading: "Video Response",
    responseTime: "Response Time",
    pleaseWait: "Please wait",
    prepTime: "Prep Time",
    skipBtn: {
      question: "Skip Question",
      lastQuestion: "Skip & End",
      response: "Skip Response",
    },
    submitBtn: {
      response: "Submit Response",
      recording: "Start Recording",
      retry: "Retry Upload",
      transcription: "Finishing transcription",
    },
    videoPanelTitle: "Rec",
    confirmPopUp: {
      title: { response: "Confirm Submission?", skip: "Skip Question?" },
      content: {
        response:
          "Proceed to the next question? You won't be able to return or modify this response.",
        skip: "Are you sure you want to skip this question? You won't be able to return or answer it later.",
      },
      cancelBtn: { response: "Cancel", skip: "Cancel" },
      saveBtn: { response: "Proceed", skip: "Skip" },
    },
    screenSharePopUp: {
      title: "You turned off your screen sharing",
      content: {
        screenShareViolation:
          "You have stopped screen sharing more than once. According to the interview process rules, you must now exit the test.",
        notRefreshed:
          "Please note that sharing your Entire screen is a crucial part of the interview process and it must be enabled. Remember, the screen can only be refreshed once. Any subsequent refresh will require you to leave the test. Ensure that screen sharing is active for the process to continue without interruption",
        refreshed:
          "You have refreshed the page more than once. As per the guidelines, you are now required to submit or leave the test.",
      },
      saveBtnText: {
        screenShareViolation: "Leave Test",
        refreshed: "Leave Test",
        notRefreshed:
          "I understand and confirm: Reload the page and Enable Screen Sharing",
        permissionDenied: "I understand and confirm:  Enable Screen Sharing",
      },
    },
    screenShareErrorMessage:
      "Permission required! Please retry and select entire screen!",
    uploadErrorMessage: "Failed to upload video. Please try again",
    slowSpeedMessage:
      "Your connection seems slow. Please be patient while uploading.",
    mediaVideoErrorMessage:
      "Failed to start video recording. Please try starting the test again.",
    videoErrorMessage:
      "Looks like there was a problem with the video recording. Don’t worry, just start the test again.",
    loadingText: "Finalizing upload",
    fullscreenPopUp: {
      title: "Enable Fullscreen Mode",
      content: {
        countLessThan3:
          "You need to enable fullscreen mode to continue taking the test",
        countGreaterThan3:
          "You have made 3 attempts to exit the screen, you will not be able to take the test again",
      },
      saveBtn: {
        countLessThan3: "OK",
        countGreaterThan3: "Leave",
      },
    },
    editor: {
      codePlaceholder: "Type your code here",
      textPlaceholder: "Type your text here",
    },
    suspiciousActivityAlert: "Suspicious activity detected",
    resumeQuestionGeneratorPrompt:
      "Scan the provided resume and generate three interview questions tailored to the candidate's experience, skills and education. Format the output as a JSON array containing strings of the questions, without additional keys or identifiers.",
    tabSwitchPopUp: {
      title: "Please stay on the test tab",
      content: {
        countLessThan3:
          "Please focus on the test tab and close all other tabs to continue.",
        countGreaterThan3:
          "You have made 3 attempts to switch to other tabs, you will not be able to take the test again",
      },
    },
  },

  feedbackScreen: {
    tagNames: {
      first: "Overall Experience",
      second: "Service",
      third: "Customer Support",
      fourth: "Efficiency",
      fifth: "Transparency",
      sixth: "Tests",
    },
    heading:
      "Your test has been received successfully and sent to the recruiter for evaluation. You can expect to hear from them very soon.",
    subHeader: "We'd love to hear your feedback until then.",
    emojiHeader: "How satisfied are you overall with our application?",
    tagsHeader: "Tell us what can be improved?",
    suggestionTextAreaPlaceholder: "Other Suggestion.....",
    submitBtn: "Submit",
    sucessfulFeedbackSubmissionMessage:
      " Thank you for submitting your feedback! Your response has been received. You may now close this page",
  },
  sessionTimeout: {
    header: "Sorry, your session has timed out.",
    subHeader: "Click here to login again",
  },
  createTest: {
    jobRoleLabel: "Add Job Role",
    addExperienceLabel: "Add Experience",
    customJobRoleLabel: "Add Custom job role",
    jobDescPlaceholder: "Enter your job description",
    generateJobDescBtn: "Generate job Description",
    jobDescCharLimit: "characters (200 character min)",
    loaderText: "Please wait your questions are being generated by AI",
    skillsHeader: "Skills",
    addSkillsPlaceholder: "Add another skill",
    addSkillBtn: "Add Skill",
    additionalQuesPlaceholder: "Enter your Question",
    addAdditionalQuesBtn: "Add additional questions",
    typeLabel: "Type",
    addQuesBtn: "Add Question",
    prevBtn: "Prev",
    nextBtn: { next: "Next", create: "Create Test" },
    customJobRoleValidationMessage:
      "Only mixed alphanumeric and just characters is allowed",
    addSkillsValidationMessage:
      "Only mixed alphanumeric and just characters is allowed",
    maximumSkillsSelectionValidationMessage:
      "You can only select up to 3 skills",
    ApiTimeOutMessage: "Sorry, an error occurred. Please try again!",
    addScreeningQuesBtn: "Add Screening Questions",
    quesCategoryLabel: "Category",
    quesCategoryHeader: "Select Questions",
    tableHeader: {
      quesTitle: "Screening Questions",
    },
    quesBankTitle: "Question Bank",
    quesIndicatorText: "Questions",
    singleLineTagText: "Single Line Text",
    screeningQuesPlaceholder: "Enter Screening Question",
    noOfQuesInputLabel: "Number of questions",
    selectedSkillHeader: "Choose the number of questions for each skill",
    totalSkillsQuestions: "Total Questions:",
    maxQuesValidationsErrMessage: "A maximum of 50 questions are permitted",
    replaceToolTip: "Replace",
    deleteToolTip: "Delete",
    requiredToolTip: "Make Question Required",
    followUpTagText: "Follow Up",
    apiErrorMessage: "Sorry, an error occurred. Please try again!",
    stepperTitles: {
      1: "Job Description",
      2: "Required Skills",
      3: "AI-Interviewer Questions",
      4: "Screening Questions",
      5: "Additional Settings",
      6: "Review",
    },
    questionTypes: {
      select: { value: "Select", color: "#007BFF" },
      singleLine: { value: "Single Line Text", color: "#32CD32" },
      multiSelect: { value: "Multi-select", color: "#FFA500" },
      url: { value: "URL", color: "#800080" },
      attachment: { value: "Attachment", color: "#A9A9A9" },
    },
    quesTypeDropdownLabel: "Type",
    addOptionBtn: "Add Option",
    optionPlaceholder: "Enter the text here",
    reviewStep: {
      accordianSubText: "Questions",
      editBtn: "Edit",
      testName: "Test Name",
      totalTime: "Total Time",
    },
    sectionTypeLabel: "Section type",
    generateResumeQuesLabel:
      "Ask the candidate 2-3 personalized questions based on the information and experience listed on his resume?",
    additionalSettings: {
      candidateDetailsHeader: "Candidate details to collect",
      candidateDetailsSelectAllToolTip: {
        off: "On check, selects all the fields of the candidate details",
        on: "On Uncheck, deselects all fields of the candidate details",
      },
      settingsSelectAllLabel: "Select All",
      candidateDetailsSubHeader:
        "Would you like to request details from the candidate, such as their LinkedIn URL and resume, before they begin the test?",
    },
    resumeFieldValidationMessage:
      "Please select resume field before proceeding. This is required when selecting Generate Resume Questions",
    startDateValidationMessage:
      "Please choose Start Date field before continuing. This field is required",
    deleteBtnText: "Delete",
    resumeQuesSubheader: "Refer to the sample questions below",
    sampleQuesHeader: "Sample Questions",
    resumeSampleQuestions: [
      {
        id: 1,
        question:
          "Looking at your experience with React, Java and Ajax at TestDojo, how do you approach learning and implementing new technologies in your projects, and can you talk about a specific instance where you had to adapt to a new technology or framework?",
      },
      {
        id: 2,
        question:
          "I noticed you've played a role in transitioning teams from a waterfall to an Agile methodology. Can you describe how you managed this transition and the impact it had on the team's efficiency and product delivery?",
      },
    ],
    alertPopupObj: {
      questions: {
        title: "Update Interview Questions?",
        content:
          "You have made changes to the job description or skills. Do you want to regenerate the interview questions based on these updates?",
        leftBtn: "Regenerate Questions",
        rightBtn: "Keep Current Questions",
      },
      skills: {
        title: "Update skills?",
        content:
          "You have made changes to the job description or role or experience . Do you want to regenerate the skills based on these updates?",
        leftBtn: "Regenerate Skills",
        rightBtn: "Keep Current Skills",
      },
    },
    mandatoryChipLabel: "Required",
  },
  editTest: {
    jobRoleLabel: "Add Job Role",
    addExperienceLabel: "Add Experience",
    customJobRoleLabel: "Add Custom job role",
    jobDescPlaceholder: "Enter your job description",
    generateJobDescBtn: "Generate job Description",
    jobDescCharLimit: "characters (200 character min)",
    loaderText: "Please wait your questions are being generated by AI",
    skillsHeader: "Skills",
    addSkillsPlaceholder: "Add another skill",
    addSkillBtn: "Add Skill",
    sampleQuesHeader: "Sample Questions",
    additionalQuesPlaceholder: "Enter your Question",
    addAdditionalQuesBtn: "Add additional questions",
    typeLabel: "Type",
    addQuesBtn: "Add Question",
    prevBtn: "Prev",
    nextBtn: { next: "Next", update: "Update Test" },
    customJobRoleValidationMessage:
      "Only mixed alphanumeric and just characters is allowed",
    addSkillsValidationMessage:
      "Only mixed alphanumeric and just characters is allowed",
    maximumSkillsSelectionValidationMessage:
      "You can only select up to 3 skills",
    ApiTimeOutMessage: "Sorry, an error occurred. Please try again!",
    addScreeningQuesBtn: "Add Screening Questions",
    quesCategoryLabel: "Category",
    quesCategoryHeader: "Select Questions",
    tableHeader: {
      quesTitle: "Screening Questions",
    },
    quesBankTitle: "Question Bank",
    quesIndicatorText: "Questions",
    singleLineTagText: "Single Line Text",
    screeningQuesPlaceholder: "Enter Screening Question",
    noOfQuesInputLabel: "Number of questions",
    selectedSkillHeader: "Choose the number of questions for each skill",
    totalSkillsQuestions: "Total Questions:",
    maxQuesValidationsErrMessage: "A maximum of 50 questions are permitted",
    replaceToolTip: "Replace",
    deleteToolTip: "Delete",
    followUpTagText: "Follow Up",
    apiErrorMessage: "Sorry, an error occurred. Please try again!",
    stepperTitles: {
      1: "Job Description",
      2: "Required Skills",
      3: "AI-Interviewer Questions",
      4: "Screening Questions",
      5: "Additional Settings",
      6: "Review",
    },
    questionTypes: {
      select: { value: "Select", color: "#007BFF" },
      singleLine: { value: "Single Line Text", color: "#32CD32" },
      multiSelect: { value: "Multi-select", color: "#FFA500" },
      url: { value: "URL", color: "#800080" },
      attachment: { value: "Attachment", color: "#A9A9A9" },
    },
    quesTypeDropdownLabel: "Type",
    addOptionBtn: "Add Option",
    optionPlaceholder: "Enter the text here",
    reviewStep: {
      accordianSubText: "Questions",
      editBtn: "Edit",
      testName: "Test Name",
      totalTime: "Total Time",
    },
    candidateDetailsLabel: "Candidate Details",
    sectionTypeLabel: "Section type",
    additionalSettings: {
      candidateDetailsToolTip: {
        on: "Toggle off to hide the candidate details page",
        off: "Toggle on  to show the candidate details page",
      },
    },
    alertPopupObj: {
      questions: {
        title: "Update Interview Questions?",
        content:
          "You have made changes to the job description or skills. Do you want to regenerate the interview questions based on these updates?",
        leftBtn: "Regenerate Questions",
        rightBtn: "Keep Current Questions",
      },
      skills: {
        title: "Update skills?",
        content:
          "You have made changes to the job description or role or experience . Do you want to regenerate the skills based on these updates?",
        leftBtn: "Regenerate Skills",
        rightBtn: "Keep Current Skills",
      },
    },
    resumeFieldValidationMessage:
      "Please select the 'Resume' field here before proceeding. This is required to generate questions specific to the candidate's background.",
    startDateValidationMessage:
      "Please choose Start Date field before continuing. This field is required",
  },
  candidateScreening: {
    submitBtn: {
      submit: "Submit",
      next: "Next",
    },
    header: "Screening Questions",
    answerPlaceHolder: "Enter your answer",
    screenSharePopUp: {
      title: "You turned off your screen sharing",
      content:
        "Please be aware that sharing the Entire screen is an essential component of the interview process. Ensure it is enabled. Failure to do so on the next attempt will result in the termination of the test. Ensure screen sharing is active for the procedure to proceed.",
      saveBtnText: "I understand and confirm: Reload and Enable Screen Sharing",
    },
    screenShareErrorMessage:
      "Permission required! Please retry and select entire screen!",
    urlValidationMessage: `Please enter a valid URL. Example format:
    "https://www.example.com"`,
    file: {
      placeholder: "Click to upload document or drag and drop",
      validationMessage:
        "Accepted formats: PDF, DOC/DOCX, XLS/XLSX, PPT/PPTX (max 2MB)",
      docPreviewTitle: "Document Preview",
    },
    uploadDocError:
      "Sorry, but we encountered an issue while trying to upload your Document. Please attempt the upload again.",
    loaderText: "Uploading Document",
    docEmptyError: "No file uploaded",
  },
  manageMembers: {
    header: "Manage Members",
    inviteBtn: "Invite",
    inviteSuccessMessage: "Invited Successfully",
    validEmailValidationText: "Email must be a valid email address",
    tableHeader: {
      name: "Name",
      email: "Email",
      role: "Role",
      actions: "Actions",
    },
    dropdownOptions: {
      deleteTitle: "Delete",
      deletePopupWarningMessage:
        "Deleting this user will permanently delete all the data associated  with this user. Are you sure?",
      manageRoleTitle: "Change Role",
    },
    currentUserText: "Current User",
    membersDropdown: {
      popUP: {
        roleTitle: "Manage Role",
        changeRoleBtn: "Change Role",
      },
    },
  },
  invite: {
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    emailLabel: "Email",
    roleLabel: "Role",
    inviteBtn: "Send Invite",
  },
  candidateDetails: {
    header: "Candidate Details",
    personalInfo: {
      header: "Personal Information",
      phoneNoLabel: "Phone Number",
      phoneNoValidationMessage: "Invalid phone number",
      dateStartField: {
        label: "Date Available to Start",
      },
    },
    additionaInfo: {
      header: "Attachments and Additional Info",
      docPreviewTitle: "Preview",
      linkedInField: {
        label: "LinkedIn Profile",
        placeholder: "Enter your LinkedIn Url",
        helperText:
          "Please enter a valid LinkedIn URL starting with https://www.linkedin.com/in/",
      },
      resumeField: {
        placeholder: "Click to upload resume or drag and drop",
        validationMessage: "PDF (max 5MB)",
      },
    },
    uploadResumeError:
      "Sorry, but we encountered an issue while trying to upload your resume. Please attempt the upload again.",
    nextBtn: "Next",
    phoneNumberValidation: {
      invalid: "Invalid phone number",
    },
    resumeEmptyError: "No file uploaded",
    loaderText: "Uploading Resume",
  },
  trialInterview: {
    trialQues: {
      header: {
        trial: "Trial question",
        review: "Review your response",
      },
      subHeader: `To ensure your audio and video are working perfectly, please look into your computer's webcam and say:`,
    },
    trialContent: {
      list: [
        "Welcome to TestDojo's video interview platform! We're excited to have you here. Here's how it works:",
        "For each question, we'll give you a brief 10-second preparation window. After this, your response recording will begin. If you're ready to jump in, feel free to bypass the prep time and start recording immediately.",
        `Before diving into the main interview, let's ensure everything is running smoothly with a quick trial. Just tap the "Start Trial" button to test your audio and video. Let's make this a great experience!`,
      ],
    },
    startTestBtn: "Start Test",
    tryAgainBtn: "Try Again",
    responseTime: "Response Time",
    pleaseWait: "Please wait",
    questionTitle: "Question ",
    duration: "Duration:",
    rightPanelheading: "Audio and video connection",
    prepTime: "Prep Time",
    submitBtn: {
      response: "Submit Response",
      recording: "Start Recording",
      trial: "Start Trial",
    },
    videoPanelTitle: "Rec",
    instructions: {
      list: [
        "Ensure that the video is clear.",
        "Ensure that the audio is clear.",
        "Verify the accuracy of the transcript.",
        "If you find any discrepancies or issues, please redo the trial.",
      ],
      strings: [
        "Be advised that the video will be recorded. If a candidate is found cheating, reading from a phone, other screens, or using unauthorized materials, they may be disqualified. If you find any discrepancies or issues, please redo the trial.",
        "Once everything is in order, you're all set to proceed with the test.",
        "Thank you.",
      ],
    },
    errorMessage: "Something went wrong.Please attempt again!",
    screenSharePopUp: {
      title: "You turned off your screen sharing",
      content:
        "Please be aware that sharing the Entire screen is an essential component of the interview process. Ensure it is enabled. Failure to do so on the next attempt will result in the termination of the test. Ensure screen sharing is active for the procedure to proceed.",
      saveBtnText: {
        permissionDenied: "I understand and confirm:  Enable Screen Sharing",
        initial: "I understand and confirm: Reload and Enable Screen Sharing",
      },
    },
    screenShareErrorMessage:
      "Permission required! Please retry and select entire screen!",
  },
  pageNotFound: {
    title: "404 Page Not Found.",
    header: "Sorry, page not found!",
    subHeader:
      "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.",
    goHomeBtn: "Go to Home",
  },
  adminDashboard: {
    dashboardCardTitles: {
      client: "Total Customers",
      candidate: "Total Candidates",
      tests: "Total Tests",
    },
    tableHeader: {
      name: "Name",
      email: "Email",
      companyName: "Company Name",
    },
    searchByLabel: "Search by Name , Email",
    searchByPlaceholder: "Search by Name, Email",
    noRecordsFoundPlaceholder: "No records found with word keyword",
  },
  clientDetail: {
    clientInfoCardsTitles: {
      name: "Name",
      email: "Email",
      phoneNo: "Phone Number",
      subscriptionStartDate: "Subscription Start Date",
      subscriptionRenewalDate: "Subscription Renewal Date",
    },
    monthlyReportCardTitles: {
      applicants: "Total Applicants",
      tests: "Total Tests",
    },
    clientInfoHeader: "Client Information",
    monthlyReportHeader: "Monthly Report",
    noRecordsFoundPlaceholder:
      "No records found for the specified month and year",
    testNameHeader: "Test Name Applicants Summary",
    tableHeaderTitles: {
      testName: "Test Name",
      candidateCount: "Number Of Applicants",
    },
  },
  candidateOnboarding: {
    header: "Candidate Onboarding",
    nextBtn: "Next",
    prevBtn: "Previous",
    finishBtn: "Submit",
    contentHeader: "Step",
    uploadResumeError:
      "Sorry, but we encountered an issue while trying to upload your resume. Please attempt the upload again.",
    resumeEmptyError: "No file uploaded",
    additionaInfo: {
      header: "Attachments and Additional Info",
      docPreviewTitle: "Preview",
      linkedInField: {
        label: "LinkedIn Profile",
        placeholder: "Enter your LinkedIn Url",
        helperText:
          "Please enter a valid LinkedIn URL starting with https://www.linkedin.com/in/",
      },
      resumeField: {
        placeholder: "Click to upload resume or drag and drop",
        validationMessage: "PDF (max 5MB)",
      },
    },
    loaderText: "Uploading Resume",
    skillsValidationMessage:
      "Please add your skills. This section is required.",
    viewResumeBtn: "View Resume",
    submitSuccessMessage: "Your details have been submitted successfully!",
    submitErrorMessage: "Oops! Something went wrong. Please try again.",
    skillsHeader: "Skills",
    experienceHeader: "Experience",
    skillsAddBtn: "Add",
    addExpBtn: "Add Experience",
    deleteExpBtn: "Delete Experience",
    deleteEduBtn: "Delete Education",
    addEduBtn: "Add Education",
    submitContentText:
      "Your account has been successfully created and a verification link has been sent to your email.",
    addSkillsFieldLabel: "Add skill",
    formValidations: {
      account: {
        email: {
          empty: "Email is Required",
          valid: "Email must be a valid email",
        },
        password: {
          empty: "Password is required",
          valid:
            "The password must contain 8 characters, one uppercase, one lowercase, one number, and one special case character",
        },
        confirmPassword: {
          empty: "Confirm Password is Required",
          valid: "Password does not match",
        },
      },
      personal: {
        photo: { empty: "Profile Photo is required" },
        firstName: {
          empty: "First Name is required",
          valid: "Only characters are allowed",
        },
        lastName: {
          empty: "Last Name is required",
          valid: "Only characters are allowed",
        },
        phoneNumber: {
          empty: "Phone Number is required",
          valid: "Please enter a valid phone number for the selected country",
        },
        countryCode: { empty: "Country Code is required" },
        location: { empty: "Country Code is required" },
      },
      education: {
        degree: {
          empty: "Degree is required",
          valid: "Only characters are allowed",
        },
        startDate: {
          empty: "Start Date is required",
          valid: "Start date must be less than End date",
        },
        endDate: {
          empty: "End Date is required",
          valid: "End date must be greater than Start date",
        },
        university: {
          empty: "University is required",
          valid: "Only characters are allowed",
        },
      },
      professional: {
        companyName: {
          empty: "Company Name is required",
        },
        startDate: {
          empty: "Start Date is required",
          valid: "Start date must be less than End date",
        },
        endDate: {
          empty: "End Date is required",
          valid: "End date must be greater than Start date",
        },
        position: {
          empty: "Position is required",
        },
        description: {
          empty: "Description is required",
        },
        noOfExperience: {
          empty: "Years Of Experience is Required",
          valid:
            "Number of years of experience must be a number or a dot with at least one digit",
        },
        linkedIn: {
          empty: "LinkedIn is Required",
          valid:
            "Please enter a valid LinkedIn URL starting with https://www.linkedin.com/",
        },
      },
    },
  },
  publicCandidateProfile: {
    reportCardHeading: "Candidate Profile",
    skillsHeader: "Skills",
    experienceHeader: "Experience",
    educationHeader: "Education",
    editClickBtn: "Edit Profile",
    reportCardTabNames: {
      detailSummary: "Summary",
      screeningQuestions: "Screening Questions",
    },
    linkedinBtn: "LinkedIn",
    resumeBtn: "Resume",
    docPreviewTitle: "Preview",
    testReportsHeader: "Test Reports",
    viewReportBtn: "View Report",
  },
  candidateTests: {
    heading: "Activate Your Profile: Take a Test",
    tableHeader: {
      testName: "Test Name",
      startTest: "Action",
    },
    startTestToolTip: "Start Test",
    startTestBtn: "Start Test",
  },
  profile: {
    heading: "Profile Details",
    uploadResumeError:
      "Sorry, but we encountered an issue while trying to upload your resume. Please attempt the upload again.",
    resumeEmptyError: "No file uploaded",
    additionaInfo: {
      header: "Attachments and Additional Info",
      docPreviewTitle: "Preview",
      linkedInField: {
        label: "LinkedIn Profile",
        placeholder: "Enter your LinkedIn Url",
        helperText:
          "Please enter a valid LinkedIn URL starting with https://www.linkedin.com/in/",
      },
      resumeField: {
        placeholder: "Click to upload resume or drag and drop",
        validationMessage: "PDF (max 5MB)",
      },
    },
    loaderText: "Uploading Resume",
    skillsValidationMessage:
      "Please add your skills. This section is required.",
    educationHeader: "Education Details",
    professionalHeader: "Professional Details",
    personalHeader: "Personal Details",
    updateBtn: "Update Details",
    submitSuccessMessage: "Profile Details Updated Successfully",
    viewBtn: "View Current Profile",
  },
};
