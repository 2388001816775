// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { useState, useEffect } from "react";
import companyLogo from "../../../assets/icons/profile-ninja.svg";
import { storageKeys } from "../../../constants/storageConstansts";
import { getFromLocalStorage } from "../../../utils/localStorageOps";
import { getFromStorage } from "../../../utils/sessionStorageOps";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  let [name, setName] = useState("");

  useEffect(() => {
    const nameForNav =
      getFromLocalStorage(storageKeys.firstName) ||
      getFromStorage(storageKeys.firstName);

    if (nameForNav) {
      setName(nameForNav);
    }
  }, []);
  return (
    <Box className="navAccount">
      <StyledRoot>
        <CustomAvatar
          sx={{
            backgroundColor: "#ffffff",
            // color: "#212B36",
            fontWeight: "600",
            fontSize: "1.25rem",
            width: "45px",
            height: "45px",
          }}
        >
          <img
            src={companyLogo}
            alt="company"
            className="companyLogo"
            width="45"
            height="45"
          />
        </CustomAvatar>
        {/* <img
          src={companyLogo}
          alt="company"
          className="companyLogo"
          width="45"
          height="45"
        /> */}

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="body1" noWrap>
            {name ? name : ""}
          </Typography>

          {/* <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            Developer
          </Typography> */}
        </Box>
      </StyledRoot>
    </Box>
  );
}
