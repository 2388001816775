import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
  });

const theme = createTheme({
  typography: {
    allVariants: {
      textTransform: "none",
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <StrictMode>
        <App />
      </StrictMode>
    </BrowserRouter>
  </ThemeProvider>
);
reportWebVitals();
