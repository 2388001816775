import React, { createContext, useContext, useRef } from "react";

const ScreenRecordingContext = createContext();

export const useScreenRecording = () => {
  return useContext(ScreenRecordingContext);
};

export const ScreenRecordingProvider = ({ children }) => {
  const vidBlobDataRef = useRef([]);
  let mediaRecoderRef = useRef(null);

  const startRecording = (
    stream,
    dataAvailableDelay,
    onStopCallback,
    onErrorCallback
  ) => {
    const options = { mimeType: "video/webm" };
    mediaRecoderRef.current = new MediaRecorder(stream, options);

    mediaRecoderRef.current.ondataavailable = (event) => {
      vidBlobDataRef.current.push(event.data);
    };
    mediaRecoderRef.current.start(dataAvailableDelay);
    mediaRecoderRef.current.onstop = () => {
      if (onStopCallback) {
        onStopCallback();
      }
    };
    mediaRecoderRef.current.onerror = (event) => {
      if (onErrorCallback) {
        onErrorCallback(event);
      }
    };

    // let stopped = new Promise((resolve, reject) => {
    //   mediaRecoderRef.current.onstop = resolve;
    //   mediaRecoderRef.current.onerror = (event) => {
    //     console.log(event.name, "recorder error");
    //     reject(event.name);
    //   };
    // });

    // let recorded = wait(lengthInMS).then(
    //   () => recorder.state === "recording" && recorder.stop()
    // );

    // return Promise.all([stopped, recorded]).then(() => {
    //   let recordedBlob = new Blob(data, { type: "video/webm" });
    // });
  };

  const stopCapture = (stream) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <ScreenRecordingContext.Provider
      value={{ startRecording, stopCapture, vidBlobDataRef, mediaRecoderRef }}
    >
      {children}
    </ScreenRecordingContext.Provider>
  );
};
