import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import companyLogo from "../../assets/icons/testDojoLogo.png";
// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, sx, minWidth, pathTo = "", ...other }, ref) => {
    const logoContainer = {
      width: 78,
      height: 78,
      minWidth: minWidth ?? 78,
      display: "inline-flex",
      ...sx,
    };
    const logo = (
      <Box ref={ref} component="div" sx={logoContainer} {...other}>
        <img
          src={companyLogo}
          width="78"
          height="78"
          alt="company"
          className="companyLogo"
        />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }
    return (
      <Link component={RouterLink} to={pathTo} sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
