import { Navigate, useLocation } from "react-router-dom";
import { storageKeys } from "../constants/storageConstansts";
import { getFromLocalStorage } from "../utils/localStorageOps";
import { getFromStorage } from "../utils/sessionStorageOps";
import {
  PATH_ADMIN,
  PATH_CANDIDATE_ACCOUNT,
  PATH_COMMON,
  PATH_RECRUITER,
} from "../constants/routeContants";
import { accountRoles } from "../constants/authConstants";
const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const token = getFromLocalStorage(storageKeys.authToken)
    ? getFromLocalStorage(storageKeys.authToken) !== "undefined"
    : getFromStorage(storageKeys.authToken)
    ? getFromStorage(storageKeys.authToken) !== "undefined"
    : false;
  if (!token) {
    localStorage.removeItem(storageKeys.authToken);
    sessionStorage.removeItem(storageKeys.authToken);
    return <Navigate to="/" />;
  }
  const accountRole =
    getFromLocalStorage(storageKeys.accountRole) ||
    getFromStorage(storageKeys.accountRole);
  const isRecruiter = accountRole === accountRoles.recruiter && PATH_RECRUITER;
  const isAdmin = accountRole === accountRoles.admin && PATH_ADMIN;
  const isCandidate =
    accountRole === accountRoles.candidate && PATH_CANDIDATE_ACCOUNT;
  const isRole = isRecruiter || isAdmin || isCandidate;
  let isRolePath = false;
  if (isRole) {
    const splited = pathname.split("/");
    const path = "/" + splited[1];
    isRolePath = Object.values(isRole).includes(path);
  }
  if (!isRolePath) {
    return <Navigate to={PATH_COMMON.pageNotFound} />;
  }

  return children;
};

export default ProtectedRoute;
