export default {
  login: {
    loginClick: "User login - Login click",
    keepMeSignedInClick: "User login - Keep me signed In click",
    signUpLink: "User login - Sign Up hyperlink",
    forgetPasswordLink: "User login - Forget Password hyperlink",
  },
  signUp: {
    signUpDetails: "User signup - Sign Up details",
    loginHyperlink: "User signup - Log In hyperlink",
  },
  forgetPassword: {
    sendClick: "Forget password - Send click with API payload",
    loginHyperlink: "Forget password - Log In hyperlink",
  },
  myTests: (() => {
    const pageName = "myTests-";
    return {
      createNewButtoClick: `${pageName}CreateNewButtonClick`,
      archiveToggleText: `${pageName}ArchiveToggled`,
      activeToggleText: `${pageName}ActiveToggled`,
      tableEditClick: `${pageName}Active-EditClick`,
      tableArchiveClick: `${pageName}Active-ArchiveClick`,
      tableUnArchiveClick: `${pageName}Archive-UnarchiveClick`,
      tableDeleteClick: `${pageName}Active-DeleteClick`,
      myTestsData: `${pageName}MyTestAPIdata`,
      tableRecordClick: `${pageName}tableRecordClick`,
      nextPageClickMyTests: `${pageName}nextPageClickMyTests`,
      prevPageClickMyTests: `${pageName}prevPageClickMyTests`,
      tableLinkClick: `${pageName}tableLinkClick`,
      duplicateClick: `${pageName}duplicateClick`,
      duplicateYesClick: `${pageName}duplicateYesClick`,
      duplicateApi: {
        payload: `${pageName}duplicateApi-payload`,
        response: `${pageName}duplicateApi-response`,
        error: `${pageName}duplicateApi-error`,
      },
    };
  })(),
  candidateTests: (() => {
    const pageName = "candidateTests-";
    return {
      startTestClick: `${pageName}startTestClick`,
      nextPageClick: `${pageName}nextPageClick`,
      prevPageClick: `${pageName}prevPageClick`,
      testsData: {
        payload: `${pageName}testsData-payload`,
        response: `${pageName}testsData-response`,
        error: `${pageName}testsData-error`,
      },
      publicSigninApi: {
        payload: `${pageName}publicSigninApi-payload`,
        response: `${pageName}publicSigninApi-response`,
        error: `${pageName}publicSigninApi-error`,
      },
    };
  })(),
  profile: (() => {
    const pageName = "profile-";
    return {
      profileDataApi: {
        payload: `${pageName}profileDataApi-payload`,
        response: `${pageName}profileDataApi-response`,
        error: `${pageName}profileDataApi-error`,
      },
      updateFormDetails: {
        payload: `${pageName}profileDataApi-payload`,
        response: `${pageName}profileDataApi-response`,
        error: `${pageName}profileDataApi-error`,
      },
    };
  })(),

  candidateSignIn: (() => {
    const pageName = "candidateLogin-";
    return {
      publicSiginLoginClick: `${pageName}publicTest-loginClick`,
      privateSignInContinueClick: `${pageName}privateTest-continueClick`,
      publicSigninApi: {
        payload: `${pageName}publicSigninApi-payload`,
        response: `${pageName}publicSigninApi-response`,
        error: `${pageName}publicSigninApi-error`,
      },
      privateSignInApi: {
        payload: `${pageName}privateSignInApi-payload`,
        response: `${pageName}privateSignInApi-response`,
        error: `${pageName}privateSignInApi-error`,
      },
      startTestAPI: {
        payload: `${pageName}startTestAPI-payload`,
        response: `${pageName}startTestAPI-response`,
        error: `${pageName}startTestAPI-error`,
      },
    };
  })(),
  getStarted: {
    generalInformation:
      "Get Started Page - Candidate response - General Information",
    continueClick: "Get Started Page - Continue click",
  },
  candidateVideoQuestions: {
    videoQuestionsSubmitted:
      "Video Questions Candidate page - Submitted Custom Video QnA",
    candidateResponseVideoQnA:
      "Video Questions Candidate page - current question Candidate Response Video QnA",
    fullscreenLeaveClick:
      "Video Questions Candidate page- Fullscreen warning - Leave Click",
    fullscreenOkClick:
      "Video Questions Candidate page - Fullscreen warning - Ok Click",
    submitApi: "Video Questions Candidate page - Submit API payload data",
    videoQuestionsData:
      "Video Questions Candidate page - video questions response data",
    startRecordingClick:
      "Video Questions Candidate page - Start recording click",
    skipQuestionClick: "Video Questions Candidate page - Skip question click",
    submitResponseClick:
      "Video Questions Candidate page - Submit Response click",
    candidateAnswer:
      "Video Questions Candidate page - candidate answer API payload ",
    tabSwitchLeaveClick:
      "Video Questions Candidate page- Tab switch warning - Leave Click",
    tabSwitchOkClick:
      "Video Questions Candidate page - Tab switch warning - Ok Click",
  },
  linkGeneration: (() => {
    const pageName = "Test invitation-";
    return {
      publicLinkCopyLinkClick: `${pageName}publicLink-copyLinkClick`,
      privateInviteClick: `${pageName}privateLink-inviteClick`,
      updateLinkStatusAPI: {
        payload: `${pageName}updateLinkStatus-Api-payload`,
        response: `${pageName}updateLinkStatus-Api-response`,
        error: `${pageName}updateLinkStatus-Api-error`,
      },
      testLinkApi: {
        payload: `${pageName}testLinkApi-Api-payload`,
        response: `${pageName}testLinkApi-Api-response`,
        error: `${pageName}testLinkApi-Api-error`,
      },
      reinviteMemberApi: {
        payload: `${pageName}reinviteMemberApi-Api-payload`,
        response: `${pageName}reinviteMemberApi-Api-response`,
        error: `${pageName}reinviteMemberApi-Api-error`,
      },
      getInvitedMembersListApi: {
        response: `${pageName}getInvitedMembersListApi-Api-response`,
        error: `${pageName}getInvitedMembersListApi-Api-error`,
      },
    };
  })(),
  mediaAccess: {
    nextBtnClick: {
      private: "Media Access - Private link Next Click",
      public: "Media Access - Public link Next Next Click",
    },
  },
  reportCard: (() => {
    const pageName = "reportCard-";
    return {
      reportCardApi: {
        payload: `${pageName}reportCardApi-payload`,
        response: `${pageName}reportCardApi-response`,
        error: `${pageName}reportCardApi-error`,
      },
      summaryAPI: {
        payload: `${pageName}reportCardApi-payload`,
        response: `${pageName}reportCardApi-response`,
        error: `${pageName}reportCardApi-error`,
      },
      sectionwiseQuesAnsApi: {
        payload: `${pageName}sectionwiseQuesAnsApi-payload`,
        response: `${pageName}sectionwiseQuesAnsApi-response`,
        error: `${pageName}sectionwiseQuesAnsApi-error`,
      },

      hiringStageFilter: `${pageName}Hiring stage filte`,
      saveNotesClick: `${pageName}Save notes click with API payloa`,
      notesClick: `${pageName}Notes Clic`,
      snapShotsClick: `${pageName}snapshots clic`,
      snapShotsCloseClick: `${pageName}snapshots modal close clic`,
      snapshotGalleryClick: `${pageName}snapshot gallery image clic`,
      updateLinkStatusAPI: {
        payload: `${pageName}update Link Status API payloa`,
        response: `${pageName}update Link Status API respons`,
      },
      candidateAnswerPreviewdata: `${pageName}Candidate Answer Preview dat`,
      candidateAnswerPreviewPayloaddata: `${pageName}Candidate Answer Preview payload dat`,
      updateRatingAPI: {
        payload: `${pageName}Update rating API payload dat`,
      },
      questionSelected: `${pageName}Question selection clic`,
      videoPlayed: `${pageName}Video Play Click for Question`,
      videoPaused: `${pageName}Video Pause Click for Question`,
      videoSeeked: `${pageName}Video Seeked for Question`,
      videoBuffering: `${pageName}Video Buffering for Question`,
      videoError: `${pageName}Video Error:`,
      videoAbort: `${pageName}Video Aborted for Question`,
      videoCanPlay: `${pageName}Video Can Play for Question`,
      videoCanPlayThrough: `${pageName}Video Can Play Through for Question`,
      videoDurationChange: `${pageName}Video Can Duration Change for Question`,
      videoEmptied: `${pageName}Video Emptied for Question`,
      videoEncrypted: `${pageName}Video Encrypted for Question`,
      videoEnded: `${pageName}Video Ended for Question`,
      videoLoadedData: `${pageName}Video Loaded Data for Question`,
      videoLoadedMetaData: `${pageName}Video Loaded MetaData for Question`,
      videoLoadStart: `${pageName}Video Load Start for Question`,
      videoPlaying: `${pageName}Video Playing for Question`,
      videoProgress: `${pageName}Video Progress for Question`,
      videoRateChange: `${pageName}Video Rate Change for Question`,
      videoSeeking: `${pageName}Video Seeking for Question`,
      videoStalled: `${pageName}Video Stalled for Question`,
      videoSuspend: `${pageName}Video Suspend for Question`,
      videoTimeUpdate: `${pageName}Video Time Update for Question`,
      videoVolumeChange: `${pageName}Video Volume Change for Question`,
      candidateSelectionClick: `${pageName}Candidate Selection Click`,
      clearSearchCrossClicked: `${pageName}Clear search cross clicked`,
      nextCandidateClick: `${pageName}Next Candidate Clic`,
      prevCandidateClick: `${pageName}Prev Candidate Clic`,
    };
  })(),
  adminLayout: {
    logOutText: "Logout - Logout hyperlink",
  },
  table: (() => {
    const pageName = "table-";
    return {
      tableSorting: `${pageName}-Sorting property and order`,
    };
  })(),
  myApplicants: (() => {
    const pageName = "myApplicants-";
    return {
      jobRoleFilter: `${pageName}-JobRoleFilter`,
      testNameFilter: `${pageName}-TestNameFilter`,
      resetFilter: `${pageName}-ResetFilter`,
      searchCandidates: `${pageName}-SearchCandidates`,
      clearSearchCrossClicked: `${pageName}-ClearSearchCandidatesCrossClicked`,
      applicantsDataApi: {
        payload: `${pageName}-applicantsDataApi-payload`,
        response: `${pageName}-applicantsDataApi-response`,
        error: `${pageName}-applicantsDataApi-error`,
      },
      filteredData: `${pageName}-FilteredData`,
      tableRecordClick: `${pageName}-tableRecordClick`,
      nextPageClickMyApplicants: `${pageName}-nextPageClickMyApplicants`,
      prevPageClickMyApplicants: `${pageName}-prevPageClickMyApplicants`,
      hiringStageFilterClick: `${pageName}-hiringStageFilterClick`,
    };
  })(),
  navigationBar: {
    myTestsLink: "Dashboard - My Tests clicked",
    myApplicantsLink: "Dashboard - My Applicants clicked",
    accountPopoverClick: "Dashboard - Account Popover clicked",
    profileList: {
      homeLink: "Dashboard - Home clicked",
      manageMembersLink: "Dashboard - Manage Members clicked",
      logOutLink: "Dashboard - Log out clicked",
    },
  },
  feedback: {
    submittedFeedback: "Feedback - Submitted with payload data",
  },
  summarization: (() => {
    const pageName = "summarization-";
    return {
      summarizationApi: {
        payload: `${pageName}-summarizationApi-payload`,
        response: `${pageName}-summarizationApi-response`,
        error: `${pageName}-summarizationApi-error`,
      },
      updateLinkStatusAPI: {
        payload: `${pageName}-updateLinkStatusAPI-payload`,
        response: `${pageName}-updateLinkStatusAPI-response`,
        error: `${pageName}-updateLinkStatusAPI-error`,
      },
      clearSearchCrossClicked: `${pageName}-ClearSearchCandidatesCrossClicked`,
      filters: {
        active: `${pageName}-ActiveFilterClicked`,
        all: `${pageName}-AllFilterClicked`,
      },
      tableRecordClick: `${pageName}-tableRecordClick`,
      nextPageClickSummarization: `${pageName}-nextPageClickSummarization`,
      prevPageClickSummarization: `${pageName}-prevPageClickSummarization`,
      hiringStageFilterClick: `${pageName}-hiringStageFilterClick`,
    };
  })(),
  createTest: (() => {
    const pageName = "createTest-";
    return {
      nextClick: `${pageName}-NextClick`,
      prevClick: `${pageName}-PrevClick`,
      skillPromptAPI: {
        payload: `${pageName}-SkillPromptAPI-payload`,
        response: `${pageName}-SkillPromptAPI-response`,
        error: `${pageName}-SkillPromptAPI-error`,
      },
      sampleQuestionsAPI: {
        payload: `${pageName}-SampleQuestionsAPI-payload`,
        response: `${pageName}-SampleQuestionsAPI-response`,
        error: `${pageName}-SampleQuestionsAPI-error`,
      },
      submitAndCreateTestApi: {
        payload: `${pageName}-submitAndCreateTestApi-payload`,
        response: `${pageName}-submitAndCreateTestApi-response`,
        error: `${pageName}-submitAndCreateTestApi-error`,
      },
      jobRoleAPI: {
        payload: `${pageName}-jobRoleAPI-payload`,
        response: `${pageName}-jobRoleAPI-response`,
        error: `${pageName}-jobRoleAPI-error`,
      },
      updateScreeningQuestionsAPI: {
        payload: `${pageName}-updateScreeningQuestionsAPI-payload`,
        response: `${pageName}-updateScreeningQuestionsAPI-response`,
        error: `${pageName}-updateScreeningQuestionsAPI-error`,
      },
    };
  })(),
  editTest: (() => {
    const pageName = "editTest-";
    return {
      nextClick: `${pageName}-NextClick`,
      prevClick: `${pageName}-PrevClick`,
      skillPromptAPI: {
        payload: `${pageName}-SkillPromptAPI-payload`,
        response: `${pageName}-SkillPromptAPI-response`,
        error: `${pageName}-SkillPromptAPI-error`,
      },
      sampleQuestionsAPI: {
        payload: `${pageName}-SampleQuestionsAPI-payload`,
        response: `${pageName}-SampleQuestionsAPI-response`,
        error: `${pageName}-SampleQuestionsAPI-error`,
      },
      getTestDataAPI: {
        payload: `${pageName}-getTestDataAPI-payload`,
        response: `${pageName}-getTestDataAPI-response`,
        error: `${pageName}-getTestDataAPI-error`,
      },
      updateTestAPI: {
        payload: `${pageName}-updateTestAPI-payload`,
        response: `${pageName}-updateTestAPI-response`,
        error: `${pageName}-updateTestAPI-error`,
      },
      jobRoleAPI: {
        payload: `${pageName}-jobRoleAPI-payload`,
        response: `${pageName}-jobRoleAPI-response`,
        error: `${pageName}-jobRoleAPI-error`,
      },
      updateScreeningQuestionsAPI: {
        payload: `${pageName}-updateScreeningQuestionsAPI-payload`,
        response: `${pageName}-updateScreeningQuestionsAPI-response`,
        error: `${pageName}-updateScreeningQuestionsAPI-error`,
      },
    };
  })(),
  videoInterview: (() => {
    const pageName = "VideoInterview-";
    const ques = "-Q";
    const retryCount = "-Retry-Count-";
    return {
      audioRecorderApi: {
        errorMessage: `${pageName}AudioAPI-Error`,
      },
      videoRecorderApi: {
        errorMessage: `${pageName}VideoAPI-Error`,
      },
      promptTestAPI: {
        params: `${pageName}PromptAPI-Params`,
        response: `${pageName}PromptAPI-Success`,
        error: `${pageName}PromptAPI-Error`,
      },
      preSignedUrlAPI: {
        payload: `${pageName}URLAPI-Payload${ques}`,
        response: `${pageName}URLAPI-Success${ques}`,
        error: `${pageName}URLAPI-Error${ques}`,
      },
      uploadVideoAPI: {
        payload: `${pageName}UploadAPI-Payload${ques}`,
        response: `${pageName}UploadAPI-Success${ques}`,
        error: `${pageName}UploadAPI-Error${ques}`,
      },
      transcriptAPI: {
        payload: `${pageName}TranscriptAPI-Payload${ques}`,
        response: `${pageName}TranscriptAPI-Success${ques}`,
        error: `${pageName}TranscriptAPI-Error${ques}`,
      },
      transcriptAndVideoSaveAPI: {
        payload: `${pageName}SaveTranscriptVideo-Payload${ques}`,
        response: `${pageName}SaveTranscriptVideo-Success${ques}`,
        error: `${pageName}SaveTranscriptVideo-Error${ques}`,
        followUpQuestion: `${pageName}FollowUp-Question${ques}`,
      },
      skipQuesAPI: {
        payload: `${pageName}SkipQuestionAPI-Payload${ques}`,
        response: `${pageName}SkipQuestionAPI-Response${ques}`,
        error: `${pageName}SkipQuestionAPI-Error${ques}`,
      },
      testSubmitAPI: {
        payload: `${pageName}TestSubmit-Payload`,
        response: `${pageName}TestSubmit-Success`,
        error: `${pageName}TestSubmit-Error`,
      },
      faceDetectionApi: {
        payload: `${pageName}faceDetectionApi-Payload`,
        response: `${pageName}faceDetectionApi-Success`,
        error: `${pageName}faceDetectionApi-Error`,
      },
      suspiciousActivityApi: {
        payload: `${pageName}suspiciousActivityApi-Payload`,
        response: `${pageName}suspiciousActivityApi-Success`,
        error: `${pageName}suspiciousActivityApi-Error`,
      },
      videoRecodingStart: `${pageName}VideoStart`,
      videoRecodingStop: `${pageName}VideoStop`,
      audioRecodingStart: `${pageName}AudioStart`,
      audioRecodingStop: `${pageName}AudioStop`,
      nextQuestion: `${pageName}NextQues${ques}`,
      backOrForwardClick: {
        click: `${pageName}NavClick${ques}`,
        confirmYes: `${pageName}NavConfirmYes${ques}`,
        confirmNo: `${pageName}NavConfirmNo${ques}`,
      },
      refreshButtonClick: `${pageName}RefreshClick${ques}`,
      refreshedBrowser: `${pageName}RefreshDone${ques}`,
      uploadVideoProgress: `${pageName}UploadProgress-`,
      quesNoForProg: ques,
      skipResponseBtnVisible: `${pageName}SkipBtnVisible${ques}`,
      skipResponseBtnClick: `${pageName}SkipClick${ques}`,
      submitResponseClick: `${pageName}SubmitResponseClick${ques}`,
      startRecordingClick: `${pageName}startRecordingClick${ques}`,
      skipQuestionClick: `${pageName}skipQuesClick${ques}`,
      videoQuestionsSubmitted: `${pageName}allQuesAnalyticsSubmitted${ques}`,
      candidateResponseVideoQnA: `${pageName}currentQuesAnalyticsSubmitted${ques}`,
      fullscreenLeaveClick: `${pageName}fullscreenLeaveClick${ques}`,
      fullscreenOkClick: `${pageName}fullscreenOkClick${ques}`,
      tabSwitchLeaveClick: `${pageName}tabSwitchLeaveClick${ques}`,
      tabSwitchOkClick: `${pageName}tabSwitchOkClick${ques}`,
      screenSharingEvents: {
        ended: `${pageName}screenSharingEnded${ques}`,
        fullscreen: `${pageName}screenSharing-fullscreen-permission-granted${ques}`,
        notFullScreen: `${pageName}screenSharing-fullScreen-permission-denied${ques}`,
        cancelBtnClick: `${pageName}screenSharing-cancelBtnClick${ques}`,
      },
      confirmPopup: {
        yesClick: {
          skipQues: `${pageName}ConfirmSkipQues${ques}`,
          skipResponse: `${pageName}ConfirmSkipResponse${ques}`,
          submitResponse: `${pageName}ConfirmSubmit${ques}`,
        },
        noClick: {
          skipQues: `${pageName}DeclineSkipQues${ques}`,
          skipResponse: `${pageName}DeclineSkipResponse${ques}`,
          submitResponse: `${pageName}DeclineSubmit${ques}`,
        },
      },
      autoRetry: {
        transcriptAPI: `${pageName}autoRetryTranscript${retryCount}`,
        preSignedUrlAPI: `${pageName}autoRetryPresignedUrl${retryCount}`,
        transcriptAndVideoSaveAPI: `${pageName}autoRetrySaveTranscriptVideo${retryCount}`,
        quesNo: "-Q",
      },
      timeouts: {
        videoUploadDelay: {
          set: `${pageName}videoUploadDelayTimeoutSet${ques}`,
          execute: `${pageName}videoUploadDelayTimeoutExecuted${ques}`,
        },
        retryVideoAlert: {
          set: `${pageName}retryVideoAlertTimeoutSet${ques}`,
          execute: `${pageName}retryVideoAlertTimeoutExecuted${ques}`,
        },
        skipResponseBtn: {
          set: `${pageName}skipResponseBtnTimeoutSet${ques}`,
          execute: `${pageName}skipResponseBtnTimeoutExecuted${ques}`,
        },
      },
      functions: {
        checkImageLoaded: `${pageName}checkImageLoaded-FunctionCalled${ques}`,
        handleVideoAndAudioApiSucess: `${pageName}handleVideoAndAudioApiSucess-FunctionCalled${ques}`,
        handleTestProgress: `${pageName}handleTestProgress-FunctionCalled${ques}`,
        handleDisplayQuesAnimation: `${pageName}handleDisplayQuesAnimation-FunctionCalled${ques}`,
        handleStartCaptureClick: `${pageName}handleStartCaptureClick-FunctionCalled${ques}`,
        handleStopCaptureClick: `${pageName}handleStopCaptureClick-FunctionCalled${ques}`,
        handleEndTimeAction: `${pageName}handleEndTimeAction-FunctionCalled${ques}`,
        endTest: `${pageName}endTest-FunctionCalled${ques}`,
        onBackForwardButtonEvent: `${pageName}onBackForwardButtonEvent-FunctionCalled${ques}`,
        beforeRefreshHandler: `${pageName}beforeRefreshHandler-FunctionCalled${ques}`,
        toggleFullscreen: `${pageName}toggleFullscreen-FunctionCalled${ques}`,
        getVideoQuestions: `${pageName}getVideoQuestions-FunctionCalled${ques}`,
        startVideoRecording: `${pageName}startVideoRecording-FunctionCalled${ques}`,
        handleVideoDataAvailable: `${pageName}handleVideoDataAvailable-FunctionCalled${ques}`,
        handleVideoRecorderStop: `${pageName}handleVideoRecorderStop-FunctionCalled${ques}`,
        handleVideoError: `${pageName}handleVideoError-FunctionCalled${ques}`,
        startAudioRecording: `${pageName}startAudioRecording-FunctionCalled${ques}`,
        audioDataAvailable: `${pageName}audioDataAvailable-FunctionCalled${ques}`,
        transcribeAudio: `${pageName}transcribeAudio-FunctionCalled${ques}`,
        generatePreSignedURL: `${pageName}generatePreSignedURL-FunctionCalled${ques}`,
        uploadVideo: `${pageName}uploadVideo-FunctionCalled${ques}`,
        handleSaveVideoAudio: `${pageName}handleSaveVideoAudio-FunctionCalled${ques}`,
        handleFollowUpQues: `${pageName}handleFollowUpQues-FunctionCalled${ques}`,
        startRetryMechanism: `${pageName}startRetryMechanism-FunctionCalled${ques}`,
        retryAPIs: `${pageName}retryAPIs-FunctionCalled${ques}`,
        nextQuestion: `${pageName}nextQuestion-FunctionCalled${ques}`,
        resetNextQuesStates: `${pageName}resetNextQuesStates-FunctionCalled${ques}`,
        stopSchedulerAndTimer: `${pageName}stopSchedulerAndTimer-FunctionCalled${ques}`,
        fixBuggyBlob: `${pageName}fixBuggyBlob-FunctionCalled${ques}`,
        convertToBlobAndSetVideoObj: `${pageName}convertToBlobAndSetVideoObj-FunctionCalled${ques}`,
        cancelResponseApis: `${pageName}cancelResponseApis-FunctionCalled${ques}`,
        handleSkipQuestionAction: `${pageName}handleSkipQuestionAction-FunctionCalled${ques}`,
        handleSkipClick: `${pageName}handleSkipClick-FunctionCalled${ques}`,
        submitAnalytics: `${pageName}submitAnalytics-FunctionCalled${ques}`,
        createFileFromBlob: `${pageName}createFileFromBlob-FunctionCalled${ques}`,
        submitAPI: `${pageName}submitAPI-FunctionCalled${ques}`,
        handleFullscreenSave: `${pageName}handleFullscreenSave-FunctionCalled${ques}`,
        setSubmitResponseTimer: `${pageName}setSubmitResponseTimer-FunctionCalled${ques}`,
        handleResponseAction: `${pageName}handleResponseAction-FunctionCalled${ques}`,
        handleRecording: `${pageName}handleRecording-FunctionCalled${ques}`,
        updateSnackbarState: `${pageName}updateSnackbarState-FunctionCalled${ques}`,
        responseTimeInSeconds: `${pageName}responseTimeInSeconds-FunctionCalled${ques}`,
        handleConfirmPopupClose: `${pageName}handleConfirmPopupClose-FunctionCalled${ques}`,
        handleConfirmPopupSave: `${pageName}handleConfirmPopupSave-FunctionCalled${ques}`,
        handleSkipResponseAction: `${pageName}handleSkipResponseAction-FunctionCalled${ques}`,
        scheduleTimer: `${pageName}scheduleTimer-FunctionCalled${ques}`,
        startTimer: `${pageName}startTimer-FunctionCalled${ques}`,
        initialiseTimer: `${pageName}initialiseTimer-FunctionCalled${ques}`,
        updateTime: `${pageName}updateTime-FunctionCalled${ques}`,
        askScreenSharePermissionAndRecord: `${pageName}askScreenSharePermissionAndRecord-FunctionCalled${ques}`,
        handleTabSwitch: `${pageName}handleTabSwitch-FunctionCalled${ques}`,
      },
    };
  })(),

  candidateScreening: (() => {
    const pageName = "candidateScreening-";
    return {
      screeningResponseAPI: {
        payload: `${pageName}ScreeningResponseAPI-payload`,
        response: `${pageName}ScreeningResponseAPI-response`,
      },
      promptTestAPI: {
        params: `${pageName}promptTestAPIparams`,
        response: `${pageName}promptTestAPIresponse`,
      },
      preSignedUrlAPI: {
        payload: `${pageName}URLAPI-Payload`,
        response: `${pageName}URLAPI-Success`,
        error: `${pageName}URLAPI-Error`,
      },
      docUploadApi: {
        payload: `${pageName}docUploadApi-Payload`,
        response: `${pageName}docUploadApi-Success`,
        error: `${pageName}docUploadApi-Error`,
      },
    };
  })(),
  manageMembers: (() => {
    const pageName = "manageMembers-";
    return {
      membersData: `${pageName}membersData`,
      inviteBtnClick: `${pageName}inviteButtonClick`,
      closeBtnInviteClick: `${pageName}closeInviteButtonClick`,
      actionBtnClick: `${pageName}actionButtonClick`,
      deleteClick: {
        payload: `${pageName}deleteClick-API-payload`,
        response: `${pageName}deleteClick-API-response`,
        error: `${pageName}deleteClick-API-error`,
      },
      manageRoleClick: {
        payload: `${pageName}manageRoleClick-API-payload`,
        response: `${pageName}manageRoleClick-API-response`,
        error: `${pageName}manageRoleClick-API-error`,
      },
    };
  })(),

  publicReport: {
    saveNotesClick: "Public Report - Save notes click with API payload",
    reportData: "Public Report - Public Report data",
    hiringStageFilter: "Public Report - Hiring stage filter",
    notesClick: "Public Report - Notes Click",
    candidateAnswerPreviewdata: "Public Review - Candidate Answer Preview data",
    candidateAnswerPreviewPayloaddata:
      "Public Review - Candidate Answer Preview payload data",
    updateRatingAPI: {
      payload: "Public Report - Update rating API payload data",
    },
    questionSelected: "Public Report - Question selection click",
    videoPlayed: "Public Report - Video Play Click for Question ",
    videoPaused: "Public Report - Video Pause Click for Question ",
    videoSeeked: "Public Report - Video Seeked for Question ",
    videoBuffering: "Public Report - Video Buffering for Question ",
    videoError: "Public Report - Video Error: ",
    videoAbort: "Public Report - Video Aborted for Question ",
    videoCanPlay: "Public Report - Video Can Play for Question ",
    videoCanPlayThrough: "Public Report - Video Can Play Through for Question ",
    videoDurationChange:
      "Public Report - Video Can Duration Change for Question ",
    videoEmptied: "Public Report - Video Emptied for Question ",
    videoEncrypted: "Public Report - Video Encrypted for Question ",
    videoEnded: "Public Report - Video Ended for Question ",
    videoLoadedData: "Public Report - Video Loaded Data for Question ",
    videoLoadedMetaData: "Public Report - Video Loaded MetaData for Question ",
    videoLoadStart: "Public Report - Video Load Start for Question ",
    videoPlaying: "Public Report - Video Playing for Question ",
    videoProgress: "Public Report - Video Progress for Question ",
    videoRateChange: "Public Report - Video Rate Change for Question ",
    videoSeeking: "Public Report - Video Seeking for Question ",
    videoStalled: "Public Report - Video Stalled for Question ",
    videoSuspend: "Public Report - Video Suspend for Question ",
    videoTimeUpdate: "Public Report - Video Time Update for Question ",
    videoVolumeChange: "Public Report - Video Volume Change for Question ",
  },
  trialInterview: (() => {
    const pageName = "trialInterview-";
    return {
      audioRecorderApi: {
        errorMessage: `${pageName}AudioAPI-Error`,
      },
      videoRecorderApi: {
        errorMessage: `${pageName}VideoAPI-Error`,
      },
      transcriptAPI: {
        payload: `${pageName}TranscriptAPI-Payload`,
        response: `${pageName}TranscriptAPI-Success`,
        error: `${pageName}TranscriptAPI-Error`,
      },
      videoRecodingStart: `${pageName}VideoStart`,
      videoRecodingStop: `${pageName}VideoStop`,
      audioRecodingStart: `${pageName}AudioStart`,
      audioRecodingStop: `${pageName}AudioStop`,
      nextQuestion: `${pageName}NextQues`,
      backOrForwardClick: {
        click: `${pageName}NavClick`,
        confirmYes: `${pageName}NavConfirmYes`,
        confirmNo: `${pageName}NavConfirmNo`,
      },
      refreshButtonClick: `${pageName}RefreshClick`,
      refreshedBrowser: `${pageName}RefreshDone`,
      submitResponseClick: `${pageName}SubmitResponseClick`,
      startRecordingClick: `${pageName}startRecordingClick`,
      startTestBtnClick: {
        privateClick: `${pageName}privateStartTestBtnClick`,
        publicClick: `${pageName}publicStartTestBtnClick`,
      },
      tryAgainBtnClick: `${pageName}tryAgainClick`,
      submitAPI: {
        payload: `${pageName}SubmitAPI-Payload`,
        response: `${pageName}SubmitAPI-Success`,
        error: `${pageName}SubmitAPI-Error`,
      },
    };
  })(),

  candidateDetails: (() => {
    const pageName = "candidateDetails-";
    return {
      resumeUploadAPI: {
        payload: `${pageName}resumeUploadAPI-Payload`,
        response: `${pageName}resumeUploadAPI-Success`,
        error: `${pageName}resumeUploadAPI-Error`,
      },
      preSignedUrlAPI: {
        payload: `${pageName}preSignedUrlAPI-Payload`,
        response: `${pageName}preSignedUrlAPI-Success`,
        error: `${pageName}preSignedUrlAPI-Error`,
      },

      candidateSubmittedData: `${pageName}Candidate-Submitted-Data`,
      nextBtnClick: `${pageName}nextBtnClick`,
    };
  })(),
  adminDashboard: (() => {
    const pageName = "adminDashboard-";
    return {
      dashboardApi: {
        payload: `${pageName}dashboardApi-Payload`,
        response: `${pageName}dashboardApi-Success`,
        error: `${pageName}dashboardApi-Error`,
      },
      clientListApi: {
        payload: `${pageName}clientListApi-Payload`,
        response: `${pageName}clientListApi-Success`,
        error: `${pageName}clientListApi-Error`,
      },
    };
  })(),
  clientDetail: (() => {
    const pageName = "adminDashboard-";
    return {
      monthlyReportApi: {
        payload: `${pageName}monthlyReportApi-Payload`,
        response: `${pageName}monthlyReportApi-Success`,
        error: `${pageName}monthlyReportApi-Error`,
      },
      clientInfoApi: {
        payload: `${pageName}clientInfoApi-Payload`,
        response: `${pageName}clientInfoApi-Success`,
        error: `${pageName}clientInfoApi-Error`,
      },
    };
  })(),
  candidateOnBoarding: (() => {
    const pageName = "candidateOnBoarding-";
    return {
      resumeUploadAPI: {
        payload: `${pageName}resumeUploadAPI-Payload`,
        response: `${pageName}resumeUploadAPI-Success`,
        error: `${pageName}resumeUploadAPI-Error`,
      },
      preSignedUrlAPI: {
        payload: `${pageName}preSignedUrlAPI-Payload`,
        response: `${pageName}preSignedUrlAPI-Success`,
        error: `${pageName}preSignedUrlAPI-Error`,
      },
      submitFormDetailsAPI: {
        payload: `${pageName}submitFormDetailsAPI-Payload`,
        response: `${pageName}submitFormDetailsAPI-Success`,
        error: `${pageName}submitFormDetailsAPI-Error`,
      },
    };
  })(),
  publicCandidateProfile: (() => {
    const pageName = "publicCandidateProfile-";
    return {
      profileDataApi: {
        payload: `${pageName}profileDataApi-Payload`,
        response: `${pageName}profileDataApi-Success`,
        error: `${pageName}profileDataApi-Error`,
      },
    };
  })(),
};
