import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
// routes
import {
  PATH_AUTH,
  PATH_RECRUITER,
  PATH_CANDIDATE_ACCOUNT,
} from "../../../constants/routeContants";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
// import { useSnackbar } from "../../../components/snackbar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import strings from "../../../constants/universalString";
import amplitudeString from "../../../constants/amplitudeString";
import { getFromLocalStorage } from "../../../utils/localStorageOps";
import { getFromStorage } from "../../../utils/sessionStorageOps";
import { storageKeys } from "../../../constants/storageConstansts";
import { accountRoles } from "../../../constants/authConstants";
import { amplitudeTrack } from "../../../utils/amplitudeTracking";

export default function AccountPopover() {
  const navigate = useNavigate();
  let [initials, setInitials] = useState("");
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  const userRole =
    getFromLocalStorage(storageKeys.accountRole) ||
    getFromStorage(storageKeys.accountRole);
  const recruiterItems = [
    {
      label: strings.navigationBar.profileDropdownOptions.homeTitle,
      linkTo: "/tests",
    },
    {
      label: strings.navigationBar.profileDropdownOptions.manageMembers,
      linkTo: "/manageMembers",
    },
  ];
  const adminItems = [];
  const candidateItems = [];
  const items =
    userRole === accountRoles.admin
      ? adminItems
      : userRole === accountRoles.candidate
      ? candidateItems
      : userRole === accountRoles.recruiter
      ? recruiterItems
      : [];

  const OPTIONS = items;
  useEffect(() => {
    const initialsForNav =
      getFromLocalStorage(storageKeys.initials) ||
      getFromStorage(storageKeys.initials);

    if (initialsForNav) {
      setInitials(initialsForNav);
    }
    const nameForNav =
      getFromLocalStorage(storageKeys.firstName) ||
      getFromStorage(storageKeys.firstName);
    if (nameForNav) {
      setName(nameForNav);
    }
    const emailForNav =
      getFromLocalStorage(storageKeys.email) ||
      getFromStorage(storageKeys.email);
    if (nameForNav) {
      setEmail(emailForNav);
    }
  }, []);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
    amplitudeTrack(amplitudeString.navigationBar.accountPopoverClick);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
    if (path === OPTIONS[0].linkTo)
      amplitudeTrack(amplitudeString.navigationBar.profileList.homeLink);
    else if (path === OPTIONS[1].linkTo)
      amplitudeTrack(
        amplitudeString.navigationBar.profileList.manageMembersLink
      );
  };
  function LogOut() {
    localStorage.clear();
    sessionStorage.clear();
    navigate(PATH_AUTH.login);
    window.location.reload(true);
    amplitudeTrack(amplitudeString.navigationBar.profileList.logOutLink);
  }
  function userAccountPageNavigation() {
    const path =
      userRole === accountRoles.recruiter
        ? PATH_RECRUITER.userAccount
        : userRole === accountRoles.candidate
        ? PATH_CANDIDATE_ACCOUNT.profile
        : null;
    if (path) navigate(path);
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          sx={{
            backgroundColor: "#FFAB00",
            color: "#212B36",
            fontWeight: "600",
            fontSize: "1.25rem",
            width: "45px",
            height: "45px",
          }}
        >
          {initials ? initials : ""}
        </CustomAvatar>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box
          sx={{ my: 1.5, px: 2.5, cursor: "pointer" }}
          onClick={userAccountPageNavigation}
        >
          <Typography variant="subtitle2" noWrap>
            {name ? name : ""}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {email ? email : ""}
          </Typography>
        </Box>
        {OPTIONS.length > 0 && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack sx={{ p: 1 }}>
              {OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleClickItem(option.linkTo)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </>
        )}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ m: 1 }} onClick={LogOut}>
          {strings.navigationBar.profileDropdownOptions.logOutOption}
        </MenuItem>
      </MenuPopover>
      {/* <Modal open={logoutConfirmModal}>
        <Box sx={style}>
          <Typography>Are you sure you want to log out?</Typography>
          <Box sx={modalFooterStyle}>
            <Button
              variant="contained"
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={() => LogOut()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn"
              onClick={() => toggleLogoutConfirm()}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
}
