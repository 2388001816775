export const storageKeys = {
  summarizationPageNo: "SummarizationPage",
  applicantsPageNo: "ApplicantsPage",
  applicantsOrder: "applicantsOrder",
  applicantsTableKey: "applicantsTableKey",
  selectedCandidate: "selectedCandidate",
  score: "score",
  hiringStage: "hiringStage",
  startDate: "startDate",
  endDate: "endDate",
  sortBy: "sortBy",
  orderBy: "orderBy",
  searchedCandidateKeyWord: "searchedCandidateKeyWord",
  dateFilter: "dateFilter",
  candidateFilter: "candidateFilter",
  videoInterviewPayload: "videoInterviewPayload",
  videoInterviewDetails: "videoInterviewDetails",
  authToken: "authToken",
  tempToken: "tempToken",
  testRefreshCount: "testRefreshCount",
  screenShareExitCount: "screenShareExitCount",
  linkDetails: "linkDetails",
  role: "role",
  email: "email",
  initials: "initials",
  firstName: "firstName",
  lastName: "lastName",
  activeFilterPageNo: "activeFilterPageNo",
  archiveFilterPageNo: "archiveFilterPageNo",
  resume: "resume",
  accountRole: "accountRole",
};
