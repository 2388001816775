import { useEffect, useState, Suspense, lazy } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
//utility and helper methods
import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { AnimatePresence } from "framer-motion";
import { useIdleTimer } from "react-idle-timer";
import { getFromLocalStorage } from "./utils/localStorageOps";
import { getFromStorage } from "./utils/sessionStorageOps";
// constants import
import {
  PATH_CANDIDATE,
  PATH_RECRUITER,
  PATH_AUTH,
  PATH_COMMON,
  PATH_ADMIN,
  PATH_CANDIDATE_ACCOUNT,
} from "./constants/routeContants";
import { storageKeys } from "./constants/storageConstansts";
import { accountRoles } from "./constants/authConstants";
//component import
import AuthenticationLayout from "./components/authLayout";
import CompactLayout from "./layouts/compact/CompactLayout";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import ProtectedRoute from "./components/protectedRoute";
import { StreamCaptureProvider } from "./contexts/streamContext";
import { ScreenRecordingProvider } from "./contexts/screenRecordingContext";
import { TestDataChangesProvider } from "./contexts/testDataChangesContext";
import Loader from "./components/loader";
import SnackbarProvider from "./components/snackbar/SnackbarProvider";
import { MotionLazyContainer } from "./components/animate";
// Auth imports
const Login = lazy(() => import("./pages/auth/login"));
const SignUp = lazy(() => import("./pages/auth/signup"));
const ForgetPassword = lazy(() => import("./pages/auth/forgetPassword"));
const ResetPassword = lazy(() => import("./pages/auth/resetPassword"));
//admin imports
const AdminDashboard = lazy(() => import("./pages/admin/dashboard"));
const ClientDetail = lazy(() => import("./pages/admin/clientDetail"));
//recruiter imports
const MyTests = lazy(() => import("./pages/recruiter/myTests"));
const MyApplicants = lazy(() => import("./pages/recruiter/myApplicants"));
const Summarization = lazy(() => import("./pages/recruiter/summarization"));
const LinkGeneration = lazy(() => import("./pages/recruiter/linkGeneration"));
const SessionTimeout = lazy(() => import("./pages/recruiter/sessionTimeout"));
const Pricing = lazy(() => import("./pages/recruiter/pricing"));
const ReportCard = lazy(() => import("./pages/recruiter/reportCard"));
const UserAccountPage = lazy(() => import("./pages/recruiter/userAccountPage"));
const CreateTest = lazy(() => import("./pages/recruiter/createTest"));
const EditTest = lazy(() => import("./pages/recruiter/editTest"));
const ManageMembers = lazy(() => import("./pages/recruiter/manageMembers"));
const ReportTranscript = lazy(() =>
  import("./pages/recruiter/reportTranscript")
);
//candidate imports
const TrialVideoInterview = lazy(() =>
  import("./pages/candidate/trialVideoInterview")
);
const CandidateScreening = lazy(() => import("./pages/candidate/screening"));
const VideoInterview = lazy(() => import("./pages/candidate/videoInterview"));
const FeedbackScreen = lazy(() => import("./pages/candidate/feedbackScreen"));
const CandidateSignIn = lazy(() => import("./pages/candidate/signin"));
const GetStarted = lazy(() => import("./pages/candidate/getStarted"));
const MediaAccess = lazy(() => import("./pages/candidate/mediaAccess"));
const CandidateDetails = lazy(() => import("./pages/candidate/details"));
//candidate account imports
const CandidateTests = lazy(() =>
  import("./pages/candidateAccount/candidateTests")
);
const Profile = lazy(() => import("./pages/candidateAccount/profile"));
//common imports
const PageNotFound = lazy(() => import("./pages/common/404"));
const PublicReportTranscript = lazy(() =>
  import("./pages/common/publicReportTranscript")
);
const PublicReport = lazy(() => import("./pages/common/publicReport"));
const CandidateOnboarding = lazy(() =>
  import("./pages/common/candidateOnboarding")
);
const PublicTestCandidates = lazy(() =>
  import("./pages/common/publicTestCandidates")
);
const PublicCandidateProfile = lazy(() =>
  import("./pages/common/publicCandidateProfile")
);
function App() {
  let location = useLocation();
  let navigate = useNavigate();
  const [isAuthenticated, setAuthenticated] = useState(false);
  let [signedInCheck, setSignedInCheck] = useState(false);

  useEffect(() => {
    const isUserAlreadyLoggedIn =
      getFromLocalStorage(storageKeys.authToken) ||
      getFromStorage(storageKeys.authToken);

    const requiredRouteExists = Object.values(PATH_RECRUITER).some(
      (route) =>
        route &&
        (location.pathname.includes(route) || location.pathname === "/")
    );
    if (window.history.length < 3) {
      setAuthenticated(true);
      if (requiredRouteExists) {
        if (isUserAlreadyLoggedIn) {
          const accountRole =
            getFromLocalStorage(storageKeys.accountRole) ||
            getFromStorage(storageKeys.accountRole);
          const path =
            accountRole === accountRoles.recruiter
              ? PATH_RECRUITER.myTests
              : accountRole === accountRoles.admin
              ? PATH_ADMIN.dashboard
              : accountRole === accountRoles.candidate
              ? PATH_CANDIDATE_ACCOUNT.profile
              : "";
          navigate(path, { replace: true });
        } else {
          setAuthenticated(false);
        }
      }
    } else {
      setAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    // initialize the client
    initializeAmplitude();
  }, []);
  //redirect old public link to new public link
  useEffect(() => redirectOldLinkToNewlink(), [location.pathname]);
  const redirectOldLinkToNewlink = () => {
    const path = location.pathname;
    const oldLink = "/candidateTest/signIn";
    const isOldLink = path && path.includes(oldLink);
    if (isOldLink) {
      const newLink = path.replace(oldLink, "/signIn");
      navigate(newLink);
    }
  };
  //function to initialize the amplitude browser sdk
  const initializeAmplitude = () => {
    const amplitudeAPIKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    amplitude.init(amplitudeAPIKey, {
      defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    });
  };
  // if idle for 30 mins then end the session and log out
  const onIdle = () => {
    const notRequiredRouteExists = location.pathname.includes(
      PATH_AUTH.forgotPassword
    );
    const token = sessionStorage.getItem(storageKeys.authToken);
    if (!notRequiredRouteExists && token && !signedInCheck) {
      navigate(PATH_COMMON.sessionTimeout);
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload(true);
    }
  };
  //hook for react idle timer
  const {} = useIdleTimer({
    timeout: 30 * 60000,
    onIdle: onIdle,
  });

  return isAuthenticated == null ? null : (
    <StreamCaptureProvider>
      <ScreenRecordingProvider>
        <div className="app">
          {/* Definition of the routes */}
          <MotionLazyContainer>
            <AnimatePresence>
              <SnackbarProvider>
                <Suspense fallback={<Loader color="#00ab55" />}>
                  <Routes location={location} key={location.pathname}>
                    <Route element={<AuthenticationLayout />}>
                      <Route path={PATH_AUTH.signup} element={<SignUp />} />
                      <Route
                        path={PATH_AUTH.login}
                        element={
                          <Login
                            signedInCheck={signedInCheck}
                            setSignedInCheck={setSignedInCheck}
                          />
                        }
                      />
                      <Route element={<CompactLayout />}>
                        <Route
                          path={PATH_AUTH.forgotPassword}
                          element={<ForgetPassword />}
                        />
                        <Route
                          path={PATH_AUTH.resetPassword}
                          element={<ResetPassword />}
                        >
                          <Route path=":id" element={<ResetPassword />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute>
                          <DashboardLayout />
                        </ProtectedRoute>
                      }
                    >
                      <Route path={PATH_RECRUITER.myTests}>
                        <Route
                          index
                          element={
                            <ProtectedRoute>
                              <MyTests />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path=":id"
                          element={
                            <ProtectedRoute>
                              <Summarization />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path={PATH_RECRUITER.link}
                          element={
                            <ProtectedRoute>
                              <LinkGeneration />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path={PATH_RECRUITER.myApplicants}
                        element={
                          <ProtectedRoute>
                            <MyApplicants />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_RECRUITER.userAccount}
                        element={
                          <ProtectedRoute>
                            <UserAccountPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_RECRUITER.manageMembers}
                        element={
                          <ProtectedRoute>
                            <ManageMembers />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_RECRUITER.createTest}
                        element={
                          <ProtectedRoute>
                            <TestDataChangesProvider>
                              <CreateTest />
                            </TestDataChangesProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={`${PATH_RECRUITER.editTest}/:testID`}
                        element={
                          <ProtectedRoute>
                            <TestDataChangesProvider>
                              <EditTest />
                            </TestDataChangesProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_RECRUITER.reportCard}
                        element={
                          <ProtectedRoute>
                            <ReportCard />
                          </ProtectedRoute>
                        }
                      >
                        <Route
                          path=":testID"
                          element={
                            <ProtectedRoute>
                              <ReportCard />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path={`${PATH_RECRUITER.transcript}/:testID/:sectionName/:reportID`}
                        element={
                          <ProtectedRoute>
                            <ReportTranscript />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_ADMIN.dashboard}
                        element={
                          <ProtectedRoute>
                            <AdminDashboard />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_ADMIN.clientDetail}
                        element={
                          <ProtectedRoute>
                            <ClientDetail />
                          </ProtectedRoute>
                        }
                      >
                        <Route
                          path=":clientID"
                          element={
                            <ProtectedRoute>
                              <ClientDetail />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path={PATH_CANDIDATE_ACCOUNT.tests}
                        element={
                          <ProtectedRoute>
                            <CandidateTests />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={PATH_CANDIDATE_ACCOUNT.profile}
                        element={
                          <ProtectedRoute>
                            <Profile />
                          </ProtectedRoute>
                        }
                      />
                    </Route>

                    <Route
                      path={PATH_CANDIDATE.videoInterview}
                      element={<VideoInterview />}
                    >
                      <Route path=":testID" element={<VideoInterview />}>
                        <Route path=":token" element={<VideoInterview />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.mediaAccess}
                      element={<MediaAccess />}
                    >
                      <Route path=":testID" element={<MediaAccess />}>
                        <Route path=":token" element={<MediaAccess />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.getStarted}
                      element={<GetStarted />}
                    >
                      <Route path=":testID" element={<GetStarted />}>
                        <Route path=":token" element={<GetStarted />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.submitted}
                      element={<FeedbackScreen />}
                    >
                      <Route path=":testID" element={<FeedbackScreen />}>
                        <Route path=":token" element={<FeedbackScreen />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.signIn}
                      element={<CandidateSignIn />}
                    >
                      <Route path=":testID" element={<CandidateSignIn />}>
                        <Route path=":token" element={<CandidateSignIn />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.trialInterview}
                      element={<TrialVideoInterview />}
                    >
                      <Route path=":testID" element={<TrialVideoInterview />}>
                        <Route
                          path=":token"
                          element={<TrialVideoInterview />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.screening}
                      element={<CandidateScreening />}
                    >
                      <Route path=":testID" element={<CandidateScreening />}>
                        <Route path=":token" element={<CandidateScreening />} />
                      </Route>
                    </Route>
                    <Route
                      path={PATH_CANDIDATE.details}
                      element={<CandidateDetails />}
                    >
                      <Route path=":testID" element={<CandidateDetails />}>
                        <Route path=":token" element={<CandidateDetails />} />
                      </Route>
                    </Route>

                    <Route
                      path={PATH_COMMON.sessionTimeout}
                      element={<SessionTimeout />}
                    />
                    <Route path={PATH_COMMON.pricing} element={<Pricing />} />
                    <Route path={PATH_COMMON.public} element={<PublicReport />}>
                      <Route path="test" element={<PublicReport />}>
                        <Route path=":testID" element={<PublicReport />}>
                          <Route path="report" element={<PublicReport />}>
                            <Route
                              path=":reportID"
                              element={<PublicReport />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path={`${PATH_COMMON.transcript}/:testID/:sectionName/:reportID`}
                      element={<PublicReportTranscript />}
                    />
                    <Route
                      path={PATH_COMMON.candidateOnboarding}
                      element={<CandidateOnboarding />}
                    />
                    <Route
                      path={`${PATH_COMMON.testCandidates}/:id`}
                      element={<PublicTestCandidates />}
                    />
                    <Route
                      path={`${PATH_COMMON.candidateProfile}/:id`}
                      element={<PublicCandidateProfile />}
                    />
                    <Route
                      path={PATH_COMMON.pageNotFound}
                      element={<PageNotFound />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to={PATH_COMMON.pageNotFound} />}
                    />
                  </Routes>
                </Suspense>
              </SnackbarProvider>
            </AnimatePresence>
          </MotionLazyContainer>
        </div>
      </ScreenRecordingProvider>
    </StreamCaptureProvider>
  );
}
export default Sentry.withProfiler(App);
