export const getFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};
export const removeFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) localStorage.removeItem(key);
};
export const setLocalStorage = (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));
