import { Box, CircularProgress, Typography } from "@mui/material";

function Loader({
  text = "",
  color = "white",
  size = 40,
  fontSize = "inherit",
}) {
  return (
    <Box className="loader">
      {text && <Typography sx={{ fontSize: fontSize }}>{text}</Typography>}
      <CircularProgress sx={{ color: color }} size={size} />
    </Box>
  );
}
export default Loader;
