import React, { createContext, useContext, useRef } from "react";

const ScreenCaptureContext = createContext();

export const useScreenCaptureContext = () => {
  return useContext(ScreenCaptureContext);
};

export const StreamCaptureProvider = ({ children }) => {
  const videoStreamRef = useRef(null);
  const audioStreamRef = useRef(null);

  return (
    <ScreenCaptureContext.Provider value={{ videoStreamRef, audioStreamRef }}>
      {children}
    </ScreenCaptureContext.Provider>
  );
};
