import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config-global";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
// import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from "./ContactsPopover";
import { alpha } from "@mui/material/styles";
// import NotificationsPopover from "./NotificationsPopover";
import amplitudeString from "../../../constants/amplitudeString";
import navConfig from "../nav/config-navigation";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router";
import {
  PATH_ADMIN,
  PATH_CANDIDATE_ACCOUNT,
  PATH_RECRUITER,
} from "../../../constants/routeContants";
import { storageKeys } from "../../../constants/storageConstansts";
import { getFromLocalStorage } from "../../../utils/localStorageOps";
import { getFromStorage } from "../../../utils/sessionStorageOps";
import { accountRoles } from "../../../constants/authConstants";
import { amplitudeTrack } from "../../../utils/amplitudeTracking";
Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = true || themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const userRole =
    getFromLocalStorage(storageKeys.accountRole) ||
    getFromStorage(storageKeys.accountRole);
  const handleNavItemClick = (pageObj) => {
    navigate(pageObj.path);
    if (pageObj.path === PATH_RECRUITER.myTests) {
      amplitudeTrack(amplitudeString.navigationBar.myTestsLink);
    } else if (pageObj.path === PATH_RECRUITER.myApplicants) {
      amplitudeTrack(amplitudeString.navigationBar.myApplicantsLink);
    }
    clearPage();
  };
  const clearPage = () => {
    const page = localStorage.getItem("page");
    if (page) {
      localStorage.removeItem("page");
    }
  };
  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };
  const pathTo =
    userRole === accountRoles.admin
      ? PATH_ADMIN.dashboard
      : userRole === accountRoles.recruiter
      ? PATH_RECRUITER.myTests
      : userRole === accountRoles.candidate
      ? PATH_CANDIDATE_ACCOUNT.tests
      : "";
  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && (
        <Logo pathTo={pathTo} sx={{ mr: 2.5 }} />
      )}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      {/* 
      <Searchbar /> */}
      {navConfig(userRole)[0].items.map((page, index) => (
        <Button
          key={page.path}
          onClick={() => handleNavItemClick(page)}
          style={location.pathname === page.path ? activeStyle : null}
          sx={{ mr: 2, marginLeft: index === 0 ? "32px" : 0, color: "#637381" }}
        >
          {page.title}
        </Button>
      ))}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
