// routes
// import { PATH_DASHBOARD } from "../../../pages/paths";
// // components
// import Label from "../../../components/label";
// import Iconify from "../../../components/iconify";
import SvgColor from "../../../components/svg-color";
import { accountRoles } from "../../../constants/authConstants";
import {
  PATH_ADMIN,
  PATH_CANDIDATE_ACCOUNT,
  PATH_RECRUITER,
} from "../../../constants/routeContants";
import strings from "../../../constants/universalString";
// ----------------------------------------------------------------------
const { titles, adminDashboard, candidate } = strings.navigationBar;
const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const adminItems = [
  {
    title: adminDashboard,
    path: PATH_ADMIN.dashboard,
    icon: ICONS.dashboard,
  },
];
const recruiterItems = [
  {
    title: titles.myTests,
    path: PATH_RECRUITER.myTests,
    icon: ICONS.dashboard,
  },
  {
    title: titles.myApplicants,
    path: PATH_RECRUITER.myApplicants,
    icon: ICONS.ecommerce,
  },
];
const candidateItems = [
  {
    title: candidate.profile,
    path: PATH_CANDIDATE_ACCOUNT.profile,
    icon: ICONS.dashboard,
  },
  {
    title: candidate.test,
    path: PATH_CANDIDATE_ACCOUNT.tests,
    icon: ICONS.dashboard,
  },
];
const navConfig = (userRole) => {
  const items =
    userRole === accountRoles.admin
      ? adminItems
      : userRole === accountRoles.candidate
      ? candidateItems
      : accountRoles.recruiter
      ? recruiterItems
      : [];
  return [
    {
      items: items,
    },
  ];
};
export default navConfig;
