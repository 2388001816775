import React, { createContext, useContext, useRef } from "react";

const TestDataChangesContext = createContext();

export const useTestDataChangesContext = () => {
  return useContext(TestDataChangesContext);
};

export const TestDataChangesProvider = ({ children }) => {
  const isDataChangesRef = useRef({ 0: false, 1: false });
  return (
    <TestDataChangesContext.Provider value={{ isDataChangesRef }}>
      {children}
    </TestDataChangesContext.Provider>
  );
};
