export const getFromStorage = (key) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};
export const removeFromStorage = (key) => {
  const item = sessionStorage.getItem(key);
  if (item) sessionStorage.removeItem(key);
};
export const setStorage = (key, data) =>
  sessionStorage.setItem(key, JSON.stringify(data));
